import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../utils/handlerApp";
import { useFetch } from "../../../hooks/useFecth";
import { AppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import NoDataTables from "../../noDataTable/NoDataTables";
import moment from "moment";
import { Checkbox } from "@mui/material";
import { LocationContext } from "../../../context/LocationContext";

const SubscriptionPOS = () => {
    const endpoint = 'configuration/subscription_pos';
    const api = useFetch();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [t, i18n] = useTranslation("global");
    const { location,company } = useContext(LocationContext);


    const [buttonSelected, setButtonSelected] = useState(false);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 912);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 912);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    let title = t('subscriptionsComponent.SubscriptionPos');


    //Permisos
    const [permissions,setPermissions] = useState([]);
    const {app, modeTheme } = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    }, [app])


    useEffect(() => { 
        sendRequest();
    }, [location,company])

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint,{
            location,
            company
        })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
            })
    }
    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action)
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
            break;
            case 'activateSelected':
                updateSelectedRowsStatus(1);
                break;
            case 'deactivateSelected':
                updateSelectedRowsStatus(0);
                break;

        }
    }

    const optionRequest = (id, action) => {
        if (action === "inactive") {
            let title = `${t('inactiveAlert.inactiveRecord')}`
            let confirmButtonText = `${t('inactiveAlert.inactive')}`
            let confirmButtonColor = "red"
            Swal.fire({
                icon: 'info',
                title: title,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: action === 'active' ? "#08a215" : "#be2a2a",
                cancelButtonColor: "#636c74",
                cancelButtonText: `${t('cancel')}`
            }).then((result) => {
                if (result.isConfirmed) {
                    handlerApp.setLoader(true);
                    let body = {
                        action: 'updateStatus',
                        status: (action === 'active') ? 1 : 0
                    }
                    api.put(endpoint + "/" + id, body)
                        .then(response => {
                            handlerApp.showOk(response);
                            sendRequest();
                        })
                        .catch(error => {
                            handlerApp.showError();
                            handlerApp.setLoader(false);
                        })
                }
            });
        } else if (action === "active") {
            let title = `${t('activeAlert.activeRecord')}`
            let confirmButtonText = `${t('activeAlert.active')}`
            let confirmButtonColor = "#08a215"
            Swal.fire({
                icon: 'info',
                title: title,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor,
                cancelButtonText: `${t('cancel')}`
            }).then((result) => {
                if (result.isConfirmed) {
                    handlerApp.setLoader(true);
                    let body = {
                        action: 'updateStatus',
                        status: (action === 'active') ? 1 : 0
                    }
                    api.put(endpoint + "/" + id, body)
                        .then(response => {
                            handlerApp.showOk(response);
                            sendRequest();
                        })
                        .catch(error => {
                            handlerApp.showError();
                            handlerApp.setLoader(false);
                        })
                }
            });
        }
    }

    const deleteRow = (id) => {
        Swal.fire({
            icon: 'info',
            title: `${t('DeleteAlert.DeleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('DeleteAlert.delete')}`,
            confirmButtonColor: "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint + '/' + id)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            sendRequest();
                        } else {
                            if (response.message !== "M001") {
                                handlerApp.handlerResponse(response);
                            }
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    })
            }
        });
    }

    const updateSelectedRowsStatus = (status) => {
        Swal.fire({
            icon: 'info',
            title: status === 1 ? `${t('activeAlert.activateSelectedRecords')}` : `${t('inactiveAlert.inactiveSelectedRecords')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: status === 1 ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`,
            confirmButtonColor: status === 1 ? "green" : "red",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                const ids = rowsSelected.map(row => row.subscriptionpos_id);
                let body = {
                    action: 'updateStatus',
                    status: status,
                    codigos: ids
                }
                api.put(endpoint+'/all', body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            setButtonSelected(false);
                            setRowsSelected([]);
                            sendRequest();
                            handleClearRows();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    }


    const rowSelected = (state) => {
        setButtonSelected(state.selectedRows.length > 0);
        setRowsSelected(state.selectedRows);
    }

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    
    const columns = [
        {
            id: 'subscriptionpos_id',
            name: 'ID',
            selector: row => row.subscriptionpos_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'company_name',
            name: t('subscriptionsComponent.company'),
            selector: row => row.company_name,
            sortable: true,
        },
        {
            id: 'location_name',
            name: t('subscriptionsComponent.location'),
            selector: row => row.location_name,
            sortable: true,
        },
        {
            id: 'subscriptionpos_serial',
            name: t('subscriptionsComponent.serial'),
            selector: row => row.subscriptionpos_serial,
            sortable: true,
        },
        {
            id: 'subscriptionpos_start_date',
            name: t('subscriptionsComponent.startDate'),
            selector: row => moment(row.subscriptionpos_start_date).format("DD/MM/YYYY, HH:mm"),
            sortable: true,
        },
        {
            id: 'subscriptionpos_end_date',
            name: t('subscriptionsComponent.endDate'),
            selector: row => moment(row.subscriptionpos_end_date).format("DD/MM/YYYY, HH:mm"),
            sortable: true,
        },
        {
            name: t('action'),
            cell: (row) => { return handlerApp.actions(row, permissions, 'subscriptionpos', handlerAction) },
            ignoreRowClick: true,
            button: true.toString(),
            width: "160px"
        }
    ];
    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.subscriptionpos_id && item.subscriptionpos_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.location_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.subscriptionpos_serial?.toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
    );

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    return (
        <div className="app container">
            <div className="title-section-forms">
                <h3>{title}</h3>
                <p>{t("description.subscriptionPOS")} </p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            {!isSmallScreen && buttonSelected && (
                                <>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <button className="btn-table-actions-massives" onClick={() => { handlerAction(null, 'deactivateSelected') }} style={{ backgroundColor: '#ff9b15', }}>
                                            <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_off</span>
                                            {t("tableActionsMassives.inactive")}
                                        </button>
                                        <button className="btn-table-actions-massives" onClick={() => { handlerAction(null, 'activateSelected') }} style={{ backgroundColor: '#08a215' }}>
                                            <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_on</span>
                                            {t("tableActionsMassives.active")}
                                        </button>
                                    </div>
                                </>
                            )}
                            <div className="action-buttons">
                                <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {isSmallScreen && buttonSelected && (
                        <>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <button className="btn-table-actions-massives" onClick={() => { handlerAction(null, 'deactivateSelected') }} style={{ backgroundColor: '#ff9b15', }}>
                                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_off</span>
                                    {t("tableActionsMassives.inactive")}
                                </button>
                                <button className="btn-table-actions-massives" onClick={() => { handlerAction(null, 'activateSelected') }} style={{ backgroundColor: '#08a215' }}>
                                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_on</span>
                                    {t("tableActionsMassives.active")}
                                </button>
                            </div>
                        </>
                    )}
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables/>}
                        selectableRows={(permissions?.indexOf("inactive") !== -1 || permissions?.indexOf("delete") !== -1)}
                        onSelectedRowsChange={rowSelected}
                        clearSelectedRows={toggledClearRows}
                        
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>

    )
}
export default SubscriptionPOS;