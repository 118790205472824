import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./../assets/css/BoldFilesUpload.css";
import HandlerApp from "./handlerApp";

const BoldFilesUpload = ({ returnImages, multiple, value }) => {
  const uploadSection = useRef();
  const boldInput = useRef();
  const handlerApp = HandlerApp();

  const [t, i18n] = useTranslation("global");
  const [loader, setLoader] = useState(false);
  const [formImages, setFormImages] = useState([]);

  useEffect(() => {
    if (multiple && value.length > 0) {
      value = JSON.parse(value);
    }
  }, [multiple, value]);

  useEffect(() => {
    let imageList = [];
    if (value) {
      if (multiple) {
        if (value.length > 0) {
          value.forEach(element => {
            imageList.push({
              name: element.image_url,
              src: element.image_url,
            });
          });
        }
      } else {
        imageList.push({
          name: value,
          src: value,
        });
      }
    }
    setFormImages(imageList);
    returnImages(imageList);
  }, [value]);

  const handleOnDragOver = (e) => {
    e.preventDefault();
    uploadSection.current.className = "section-bold-upload-files drag-over";
    return false;
  };

  const handleOnDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    proccessImages(files);
  };

  const handleClickAttach = (e) => {
    e.preventDefault();
    boldInput.current.click();
  };

  const onChangeUploads = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const images = e.target.files;
    proccessImages(images);
  };

  const proccessImages = (images) => {
    setLoader(true);
    let imageList = multiple ? [...formImages] : []; // Copy current images to the new list or reset if single
    let bucleLength = multiple ? images.length : 1;
    for (let i = 0; i < bucleLength; i++) {
      let image = images[i];
      let name = image.name;
      buildImage(image, name)
        .then((result) => {
          imageList.push({
            name: result.name,
            src: result.pic.src,
          });
          setFormImages(imageList);
          returnImages(imageList);
          if (i === bucleLength - 1) {
            setLoader(false);
          }
        })
        .catch((error) => {
          console.error(error);
          if (i === bucleLength - 1) {
            setLoader(false);
          }
        });
    }
  };

  const buildImage = (image, name) => {
    return new Promise((resolve, reject) => {
      let pic = new Image();
      let fr = new FileReader();
      fr.onload = function () {
        pic.src = fr.result;
      };
      fr.readAsDataURL(image);

      pic.onload = () => {
        resolve({ name, pic });
      };

      fr.onerror = reject;
    });
  };

  const removeItems = (e) => {
    e.preventDefault();
    boldInput.current.value = "";
    setFormImages([]);
    returnImages([]);
  };

  return (
    <>
      <div className="files-text">{t('profileImageDescription')}</div>
      <div className="container-uploads ml-5">
        <div
          ref={uploadSection}
          className="section-bold-upload-files"
          onDragOver={handleOnDragOver}
          onDrop={handleOnDrop}
        >
          {formImages.length > 0 ? (
            formImages.map((image, index) => (
              <img src={image.src} key={index} alt={image.name} />
            ))
          ) : (
            <div className="inner-upload">
              <button className="bold-btn" onClick={handleClickAttach}>
                <span className="material-symbols-outlined">attach_file</span>
              </button>
              <span className="title-upload">{t("dragAndDrop")}</span>
            </div>
          )}
        </div>
        <div className="buttons-options">
          <button onClick={handleClickAttach}>
            <span className="material-symbols-outlined">add</span>
            {t("add")}
          </button>
          <button onClick={removeItems}>
            <span className="material-symbols-outlined">delete_forever</span>
            {t("remove")}
          </button>
        </div>
        <input
          ref={boldInput}
          onChange={onChangeUploads}
          hidden
          type="file"
          name="images[]"
          multiple={multiple}
          id="bold-upload-files"
          accept=".png, .jpg, .jpeg, .webp"
        />
      </div>
    </>
  );
};

export default BoldFilesUpload;
