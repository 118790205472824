import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NoDataImage from '../../assets/img/EmptyState.svg'; // ajusta la ruta según sea necesario
import NoDataImageDark from '../../assets/img/EmptyStateOscuro.svg';
import { AppContext } from '../../context/AppContext';



const NoDataTables = () => {
    const [t, i18n] = useTranslation("global");
    const {modeTheme} = useContext(AppContext);

    return (
        <div className="no-data-display">
            {modeTheme ?
            <img src={NoDataImageDark} alt="No data available" className="no-data-image m-3" />
            :
            <img src={NoDataImage} alt="No data available" className="no-data-image m-3" />
         }
            <div className="no-data-display-text mt-3">
             <h5 className='fw-bold'>{t("noDataFound.noDataAvailable")}</h5>
             <p className="no-data-display-text mt-3">{t("noDataFound.noInformationHere")}</p>
             <p className="no-data-display-text mt-3">
                {t("noDataFound.infoPartone")}
                <a href="https://posbrava.com/" className="underline"> {t("noDataFound.infoPartTwo")}</a>
                {t("noDataFound.infoPartThree")}
                <a href="https://posbrava.com/" className="underline"> {t("noDataFound.infoPartFour")}</a>
             </p>
            </div>
        </div>
    );
}

export default NoDataTables;