import React, {
    createContext,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { Logout } from "../utils/BoldLogin";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const inactivityTimeout = 10 * 60 * 1000; 
    const timeoutId = useRef(null);
    const closeSession = Logout();

    const logout = useCallback(() => {
        closeSession.closeSession();
    }, [navigate]);

    const resetInactivityTimeout = useCallback(() => {
        if (timeoutId.current) clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(logout, inactivityTimeout);
    }, [logout, inactivityTimeout]);

    useEffect(() => {
        // Establece el temporizador por primera vez
        resetInactivityTimeout();

        const handleActivity = () => {
            resetInactivityTimeout();
        };
        window.addEventListener("click", handleActivity);
        window.addEventListener("keydown", handleActivity);

        return () => {
            clearTimeout(timeoutId.current);
            window.removeEventListener("click", handleActivity);
            window.removeEventListener("keydown", handleActivity);
        };
    }, [resetInactivityTimeout]);

    return (
        <AuthContext.Provider>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
