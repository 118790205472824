import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import HandlerApp from "../../../utils/handlerApp";
import DataTable from "react-data-table-component";
import FormControl from '@mui/material/FormControl';
import NoDataTables from "../../noDataTable/NoDataTables";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";

const Orders = () => {

    const endpoint   = "orders";
    const [t]        = useTranslation("global");
    const title      = t('ordersComponent.orders');
    const api        = useFetch();
    const nav        = useNavigate();
    const handlerApp = HandlerApp();
    const profile    =  handlerApp.getProfile()
    
    const { modeTheme } = useContext(AppContext);
    const { location, company } = useContext(LocationContext);

    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 468);

    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
        }
    }

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 468);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        sendRequest();
    }, [company, location])

    const handleChangeState = (event) => {
        setSelectedState(event.target.value);
    };

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint,{ location, company })
            .then(async response=> {
                if(response.success){
                    const updatedData = response.data.map(item => {
                        let typeOrder;
                        if (item.order_takeout) {
                            typeOrder = "Llevar";
                        } else if (item.order_pickup) {
                            typeOrder = "Recoger";
                        } else {
                            typeOrder = "Restaurante";
                        }
                        return { ...item, type_order: typeOrder };
                    });
                    setData(updatedData);
                    handlerApp.setLoader(false);
                }else{
                    setData([])
                    handlerApp.setLoader(false);
                    handlerApp.handlerResponse(response)
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            })
    }

    const optionRequest = (id, action) => {
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                handlerApp.showOk(response);
                sendRequest();
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const columns = [
        {
            id: 'order_id',
            name: 'Id',
            selector: row => profile > 2 ? row.order_consecutive : row.order_id,
            sortable: true,
            width: "70px"
        },
        ...(profile <= 2 ? [
            {
                id: 'order_consecutive',
                name: t('ordersComponent.order_consecutive'),
                selector: row => row.order_consecutive !== null ? row.order_consecutive : 'N/A',
                sortable: true
            }
        ] : []),
        {
            id: 'order_reference',
            name: t('ordersComponent.order_reference'),
            selector: row => row.order_reference,
            sortable: true
        },
        {
            id: 'order_expiration',
            name: t('ordersComponent.type_order'),
            selector: row => row.type_order,
            cell: row => {
                if (row.order_takeout) {
                    return <div className="text-center">
                        <img width={60} src="./icons/llevar.png" />
                        <p>{row.type_order}</p>
                    </div>
                } else if (row.order_pickup) {
                    return <div className="text-center">
                        <img width={60} src="./icons/recoger.png" />
                        <p>{row.type_order}</p>
                    </div>
                } else {
                    return <div className="text-center">
                        <img width={60} src="./icons/restaurante.png" />
                        <p>{row.type_order}</p>
                    </div>
                }
            },
            sortable: true
        },
        ...(profile <= 2 ? [
            {
                id: 'company_name',
                name: t('posComponent.company'),
                selector: row => row.company_name,
                sortable: true
            }
        ] : []),
        {
            id: 'location_name',
            name: t('locationComponent.locationName'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'setting_name',
            name: t('state'),
            selector: row => row.setting_name,
            sortable: false
        },
        {
            name: t('action'),
            cell: (row) => { return handlerApp.actions(row, 'view', 'order', handlerAction) },
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true.toString(),
            width: "160px"
        }
    ];

    const filteredItems = data?.filter(
        item => {
            const matchesFilterText = item.order_reference?.toLowerCase().includes(filterText.toLowerCase())
                || item.location_name?.toLowerCase().includes(filterText.toLowerCase())
                || item.setting_name?.toLowerCase().includes(filterText.toLowerCase())
                || item.order_id?.toString().includes(filterText.toString())
                || item.order_consecutive?.toString().includes(filterText.toString())
                || item.type_order?.toLowerCase().includes(filterText.toLowerCase())
                || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
                || item.order_consecutive?.toString().includes(filterText.toString());

            const matchesState = selectedState === '' || item.setting_name === selectedState;

            return matchesFilterText && matchesState;
        }
    );

    return (
        <div className="app container">
            <div className="title-section-forms">
                        <h3>{title}</h3>
                        <p>{t("description.orders")}</p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div className="action-buttons">
                                {!isSmallScreen &&
                                    <div style={{ paddingTop:'4px'}}>
                                        <FormControl sx={{ m: 1, mt: 0, minWidth: 120 }} size="small">
                                            <InputLabel id="demo-select-small-label">{t("state")}</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={selectedState}
                                                label={t("state")}
                                                onChange={handleChangeState}
                                                style={{ borderRadius: 8 }}    
                                            >
                                                <MenuItem value=""><em>{t('ordersComponent.all')}</em></MenuItem>
                                                <MenuItem value={"PENDING"}>{t('ordersComponent.pending')}</MenuItem>
                                                <MenuItem value={"DOING"}>{t('ordersComponent.doing')}</MenuItem>
                                                <MenuItem value={"DOING PAID"}>{t('ordersComponent.doingPaid')}</MenuItem>
                                                <MenuItem value={"COMPLETE"}>{t('ordersComponent.complete')}</MenuItem>
                                                <MenuItem value={"FREEZE"}>{t('ordersComponent.frozen')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                }        
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {isSmallScreen &&
                        <div style={{ marginLeft:'10px'}}>
                            <FormControl sx={{ m: 1, mt: 0, minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">{t("state")}</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={selectedState}
                                    label={t("state")}
                                    onChange={handleChangeState}
                                    style={{ borderRadius: 8 }}
                                >
                                    <MenuItem value=""><em>{t('ordersComponent.all')}</em></MenuItem>
                                    <MenuItem value={"PENDING"}>{t('ordersComponent.pending')}</MenuItem>
                                    <MenuItem value={"DOING"}>{t('ordersComponent.doing')}</MenuItem>
                                    <MenuItem value={"DOING PAID"}>{t('ordersComponent.doingPaid')}</MenuItem>
                                    <MenuItem value={"COMPLETE"}>{t('ordersComponent.complete')}</MenuItem>
                                    <MenuItem value={"FREEZE"}>{t('ordersComponent.frozen')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    }
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables />}
                        // subHeaderComponent={subHeaderComponentMemo}
                        //selectableRows
                        
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}

export default Orders;
