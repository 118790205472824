import React from 'react';

const MassActionButtons = ({ permissions, handlerAction, t }) => {
    return (
        <div className="mass-action-buttons">
            {permissions?.indexOf("inactive") !== -1 && (
                <>
                    <button className="btn-table-actions-massives inactive" onClick={() => handlerAction(null, 'deactivateSelected')}>
                        <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_off</span>
                        {t("tableActionsMassives.inactive")}
                    </button>
                    <button className="btn-table-actions-massives active" onClick={() => handlerAction(null, 'activateSelected')}>
                        <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>toggle_on</span>
                        {t("tableActionsMassives.active")}
                    </button>
                </>
            )}
            {permissions?.indexOf("delete") !== -1 && (
                <button className="btn-table-actions-massives delete" onClick={() => handlerAction(null, 'deleteSelected')}>
                    <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>delete</span>
                    {t("tableActionsMassives.delete")}
                </button>
            )}
        </div>
    );
};

export default MassActionButtons;
