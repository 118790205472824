import React, { useEffect, useState } from "react";


function Error404() {
    const [isDarkMode, setIsDarkMode] = useState(false);

        useEffect(() => {
            const htmlElement = document.documentElement;
            const darkMode = localStorage.getItem('darkMode');
    
            if (darkMode === 'dark') {
                htmlElement.classList.add('dark');
                setIsDarkMode(true);
            } else if (darkMode === '') {
                htmlElement.classList.remove('dark');
                setIsDarkMode(false);
            } else {
                const themeUser = window.matchMedia('(prefers-color-scheme: dark)').matches;
                if (themeUser) {
                    htmlElement.classList.add('dark');
                    setIsDarkMode(true);
                    localStorage.setItem('darkMode', 'dark');
                } else {
                    htmlElement.classList.remove('dark');
                    setIsDarkMode(false);
                    localStorage.setItem('darkMode', '');
                }
            }
        }, []);

    return (
        <div className="error-404">
            <h1>Error 404</h1>
            <p>Page not found</p>
        </div>
    )
}

export default Error404;