import Swal from "sweetalert2";
import HandlerApp from "../../../utils/handlerApp";
import DataTable from "react-data-table-component";
import NoDataTables from "../../noDataTable/NoDataTables";
import MassActionButtons from "../../molecules/MassActionButtons";
import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";

const PaymentProcessors = () => {

    const endpoint      = "configuration/payment-processor";
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    const profile       = handlerApp.getProfile();

    
    const [data, setData]                           = useState([]);
    const [permissions,setPermissions]              = useState([]);
    const [rowsSelected, setRowsSelected]           = useState([]);
    const [buttonSelected, setButtonSelected]       = useState(false);
    const [toggledClearRows, setToggleClearRows]    = useState(false);
    const [isSmallScreen, setIsSmallScreen]         = useState(window.innerWidth < 912);
    
    const { app, modeTheme } = useContext(AppContext);
    const { checkLocations, company, location } = useContext(LocationContext);


    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 912);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRecords(id)
            break;
            case 'deleteSelected':
                const selectedIds = rowsSelected.map(row => row.paymentprocessor_id);
                deleteRecords(selectedIds);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            case 'activateSelected':
                updateSelectedRowsStatus(1);
                break;
            case 'deactivateSelected':
                updateSelectedRowsStatus(0);
                break;
        }
    }

    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
            checkLocations();
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const deleteRecords = (ids) => {
        const isMultiple = Array.isArray(ids);
        const alertTitle = isMultiple ? t('DeleteAlert.DeleteSelectedRecords') : t('DeleteAlert.DeleteRecord');
        const endpointUrl = isMultiple ? `${endpoint}/all` : `${endpoint}/${ids}`;
        let body = {
            codes: ids
        }
        Swal.fire({
            icon: 'info',
            title: alertTitle,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('DeleteAlert.delete'),
            confirmButtonColor: "red",
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
    
                const request = isMultiple 
                    ? api.deleteMethod(endpointUrl, body) 
                    : api.deleteMethod(endpointUrl);
    
                request
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            if (isMultiple) {
                                setButtonSelected(false);
                                setRowsSelected([]);
                                handleClearRows();
                            }
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const updateSelectedRowsStatus = (status) => {
        Swal.fire({
            icon: 'info',
            title: status === 1 ? `${t('activeAlert.activateSelectedRecords')}` : `${t('inactiveAlert.inactiveSelectedRecords')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: status === 1 ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`,
            confirmButtonColor: status === 1 ? "#08a215" : "#d32f2f",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                const ids = rowsSelected.map(row => row.paymentprocessor_id);
                let body = {
                    action: 'updateStatus',
                    status: status,
                    codigos: ids
                }
                api.put(endpoint+'/all', body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            setButtonSelected(false);
                            setRowsSelected([]);
                            sendRequest();
                            handleClearRows();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    }

    const rowSelected = (state) => {
        setButtonSelected(state.selectedRows.length > 0);
        setRowsSelected(state.selectedRows);
    }

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }

    const columns = [
        {
            id: 'paymentprocessor_id',
            name: 'id',
            selector: row => row.paymentprocessor_id,
            sortable: true,
            width: "70px"
        },
        ...(profile <= 2 ? [
            {
                id: 'company_name',
                name: t('posComponent.company'),
                selector: row => row.company_name,
                sortable: true
            }
        ] : []),
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true,
            width: "200px"
        },
        {
            id: 'paymentprocessor_processor',
            name: t('paymentProcessorComponent.processor'),
            selector: row => row.paymentprocessor_processor ? row.paymentprocessor_processor : 'N/A',
            sortable: true
        },
        {
            id: 'paymentprocessor_merchant_id',
            name: t('paymentProcessorComponent.merchantId'),
            selector: row => row.paymentprocessor_merchant_id,
            sortable: true
        },
        {
            id: 'paymentprocessor_app_id',
            name: t('paymentProcessorComponent.appId'),
            selector: row => row.paymentprocessor_app_id,
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions(row,permissions,'paymentprocessor',handlerAction)},
            ignoreRowClick: true,
            button: true.toString(),
            width: "160px"
        }
    ];

    const sendRequest = ()=>{
        setData([]);
        handlerApp.setLoader(true);
        api.get(endpoint, {company, location})
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                if (response.message !== "M001") {
                    handlerApp.handlerResponse(response);
                }
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    useEffect(() => {
        sendRequest();
    }, [company, location])
    
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.paymentprocessor_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.paymentprocessor_processor?.toLowerCase().includes(filterText.toLowerCase())
            || item.location_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.paymentprocessor_app_id?.toLowerCase().includes(filterText.toLowerCase())
            || item.paymentprocessor_merchant_id?.toLowerCase().includes(filterText.toLowerCase())
    );

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    return (
        <div className="app container">
            <div className="title-section-forms">
                <h3>{t('paymentProcessorComponent.paymentProcessor')}</h3>
                <p>{t("description.paymentProcessor")} </p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            {!isSmallScreen && buttonSelected && (
                                <MassActionButtons
                                    permissions={permissions}
                                    handlerAction={handlerAction}
                                    t={t}
                                />
                            )}
                            <div className="action-buttons">
                                { permissions?.indexOf("create") !== -1 && (
                                    <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                        <span className="material-symbols-outlined">add</span>
                                    </button>
                                ) }
                                <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {isSmallScreen && buttonSelected && (
                        <MassActionButtons
                            permissions={permissions}
                            handlerAction={handlerAction}
                            t={t}
                        />
                    )}
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables/>}
                        selectableRows={(permissions?.indexOf("inactive") !== -1 || permissions?.indexOf("delete") !== -1)}
                        onSelectedRowsChange={rowSelected}
                        clearSelectedRows={toggledClearRows}
                        
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaymentProcessors;