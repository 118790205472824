import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const RemovedIngredientList = ({ removedIngredients }) => {
    const [t, i18n] = useTranslation("global");
    if (!removedIngredients || removedIngredients.length === 0) return null;

    return (
        <Box marginBottom={2}>
            <Typography variant="h6" gutterBottom>
                {t("ordersComponent.without")}
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
                {removedIngredients.map((item, index) => (
                    <Chip
                        key={index} 
                        label={item.item_name} 
                        variant="outlined"
                        color="error"
                        className="removed-ingredient-chip"
                    />
                ))}
            </Box>
        </Box>
    );
}

export default RemovedIngredientList;