import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";


const BoldSelect = ({title, name, options,onChange, value,dependency=null, required})=>{
    const [val,setVal] = useState("");

    useEffect(()=>{
        if(value){
            if(options.length > 0){
                setVal(value);
            }
        }
    })
    const handleChange = (e)=>{
        setVal(e.target.value);
        onChange(e,name,dependency);
    }


    return (
        <FormControl className="w-100" variant="standard" size="small" required={required !== undefined ? true : false} >
            <InputLabel id={`${title}-label`}>{title}</InputLabel>
            <Select labelId={`${title}-label`} value={val} onChange={handleChange} label={title} >
                <MenuItem value="">
                    <em>...</em>
                </MenuItem>
                {
                    options?.length > 0 &&
                    options.map(item=>(<MenuItem value={item.code} key={item.code}>{item.name}</MenuItem>))
                }
            </Select>
        </FormControl>
    )
}

export default BoldSelect;