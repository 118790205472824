import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import NoDataTables from "../../noDataTable/NoDataTables";
import { LocationContext } from "../../../context/LocationContext";
import Button from '@mui/material/Button';

const Transactions = () => {
    const endpoint = 'transactions';
    const api = useFetch();
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [columns, setColumns] = useState()

    const profile = localStorage.getItem("profile");
    const [permissions, setPermissions] = useState([]);
    const { app, modeTheme } = useContext(AppContext);

    const [t, i18n] = useTranslation("global");
    let title = t('transactionsComponent.transactions');

    const [statusStyle, setStatusStyle] = useState({});

    const styleCanceled = {
        background: '#E4B50B',
        color: 'white',
        height: '20px',
        width: '70px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };
    const styleSuccess = {
        background: '#56AC40',
        color: 'white',
        height: '20px',
        width: '70px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };
    const styleRefund = {
        background: '#d32f2f',
        color: 'white',
        height: '20px',
        width: '70px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const [data, setData] = useState([]);
    // location
    const { location,company } = useContext(LocationContext);
    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint, {
            location: location,
            company: company
        })
            .then(response => {
                if (response.success) {
                    const sortedTransactions = response.data.sort((a, b) => new Date(b.order_created_at) - new Date(a.order_created_at));
                    setData(sortedTransactions);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
            });
    };

    useEffect(() => {
        setPermissions(app.permissions[endpoint]);
        if (app.hasOwnProperty('location')) {
            sendRequest();
        }
    }, [location,company])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
        }
    }

    const optionRequest = (id, action) => {
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                handlerApp.showOk(response);
                sendRequest();
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const columnsToShow = [
        {
            id: 'tx_id',
            name: 'ID',
            selector: row => row.tx_id,
            sortable: true,
            width: "80px"
        },
        {
            id: 'order_id',
            name: t('ordersComponent.order'),
            cell: row => <a href={'/orders/view/' + row.order_id}>{profile > 2 ? row.order_consecutive : row.order_id} </a>,
            sortable: true,
            selector: row => row.order_id,
            width: "90px",
        },
        ...(profile <= 2 ? [
            {
                id: 'order_consecutive',
                name: t('ordersComponent.order_consecutive'),
                selector: row => row.order_consecutive !== null ? row.order_consecutive : 'N/A',
                sortable: true
            }
        ] : []),
        {
            id: 'tx_code',
            name: t('transactionsComponent.code'),
            selector: row => row.tx_code,
            sortable: true,
            width: "200px"
        },
        {
            id: 'tx_method',
            name: t('transactionsComponent.method'),
            cell: row => row.tx_method === 'CASH' ? t('transactionsComponent.cash') : 
                            row.tx_method === 'credit_card' ? t('transactionsComponent.credit') : 
                                row.tx_method === 'debit_card' ? t('transactionsComponent.debit'): t('transactionsComponent.other'),
            sortable: true,
            selector: row => row.tx_method,
            width: "150px"
        },
        {
            id: 'tx_amount',
            name: t('transactionsComponent.amount'),
            cell: row => handlerApp.formatCurrency(row.tx_amount),
            sortable: true,
            selector: row => row.tx_amount,
            width: "95px"
        },
        {
            id: 'tx_fecha',
            name: t('transactionsComponent.date'),
            cell: row => handlerApp.getDate(row.order_created_at),
            sortable: true,
            selector: row => row.order_created_at,
            width: "150px"
        },
        {
            id: 'tx_transaction_status',
            name: t('transactionsComponent.transaction_status'),
            cell: row => {
                if (row.tx_transaction_status === 'CLOSED' || row.tx_transaction_status === 'SUCCESS' && row.tx_order_status !== 'R') {
                    return <div style={styleSuccess}>{t('transactionsComponent.success')}</div>;
                } else if (row.tx_transaction_status === 'succeeded' || row.tx_order_status === 'R') {
                    return <div style={styleRefund}>{t('transactionsComponent.refund')}</div>;
                } else {
                    return <div style={styleCanceled}>{t('transactionsComponent.fail')}</div>;
                }
            },
            style: statusStyle,
            width: "100px"
        },
        {
            name: t('action'),
            cell: (row) => { return handlerApp.actions(row, permissions, 'tx', handlerAction) },
            ignoreRowClick: true,
            button: true.toString(),
            width: "80px"
        }
    ];


    useEffect(() => {
        // Crear un conjunto de order_id únicos
        const uniqueOrderIds = new Set(data.map(item => item.order_id));

        // Verificar si el order_id actual se repite
        const isOrderIdRepeated = (orderId) => {
            let count = 0;
            data.forEach(item => {
                if (item.order_id === orderId) {
                    count++;
                }
            });
            return count > 1;
        };

        const columnsToAdd = [];
        if (profile == 1 || profile == 2) {
            columnsToAdd.push({
                id: 'company_name',
                name: t('transactionsComponent.company'),
                cell: row => row.company_name,
                selector: row => row.company_name,
                sortable: true
            });
        }
        if (profile == 1 || profile == 2 || profile == 3 || profile == 4) {
            columnsToAdd.push({
                id: 'location_name',
                name: t('transactionsComponent.location'),
                cell: row => row.location_name,
                selector: row => row.location_name,
                sortable: true
            });
        }
        if (profile == 4) {
            columnsToAdd.push({
                id: 'refund',
                name: t('transactionsComponent.refund'),
                cell: row => {
                    if (row.tx_method === 'Credit Card' && row.tx_transaction_status !== 'succeeded' && row.order_closed === 1 && !isOrderIdRepeated(row.order_id)) {
                        return (
                            <div className="text-center">
                                <Button style={{ padding: '5px 5px', fontSize: '12px' }} variant="outlined" color="error" onClick={() => handlerAction(row.order_id, 'refund')}>
                                    {t('transactionsComponent.refund')}
                                </Button>
                            </div>
                        );
                    } else {
                        return 'N/A';
                    }
                },
                ignoreRowClick: true,
                button: true.toString(),
                Width: "100px"
            });
        }
        const columnsd = [...columnsToShow.slice(0, 3), ...columnsToAdd, ...columnsToShow.slice(3)];
        setColumns(columnsd)
    }, [data, t])
    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(item => {
        const processor = item.tx_transaction_status === 'APPROVAL' ? 'ELAVON' : 'CLOVER';

        return (
            (item.tx_id && item.tx_id.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            processor.toLowerCase().includes(filterText.toLowerCase()) ||
            (item.tx_code?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.order_id?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.order_consecutive?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.company_name?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.location_name?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.tx_transaction_status?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.tx_transaction_id?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.tx_method?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            (item.tx_card?.last4?.toString().toLowerCase().includes(filterText.toLowerCase())) ||
            handlerApp.formatCurrency((item.tx_amount)).toString().toLowerCase().includes(filterText.toLowerCase())
        );
    });

    return (

        <div className="app container">
            <div className="title-section-forms">
                <h3>{title}</h3>
                <p> {t("description.transationOrder")}</p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div className="action-buttons">
                                {/* <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                    <span className="material-symbols-outlined">add</span>
                                </button> */}
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        noDataComponent={<NoDataTables />}

                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}
export default Transactions;