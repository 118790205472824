import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";


const ItemsView = ()=>{
    const endpoint = "configuration/items";
    const [t,i18n] = useTranslation("global");
    const prefix = "item";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.item_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row np-row">
                    { (data.item_image === "" || data.item_image === undefined || data.item_image === null) && 
                        <>
                        <div className="col-md-4 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('${imageNotFound}')`}}>
                            </section>
                        </div>
                        </>
                    }
                    { data.item_image !== "" && data.item_image !== undefined && data.item_image !== null &&
                        <>
                        <div className="col-md-4 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('${data.item_image}')`}}>
                            </section>
                        </div>
                        </>
                    }
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('itemComponent.name')}:</dt>
                                <dd>{data.item_name || t('noInformation')}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('itemComponent.itemCost')}:</dt>
                                <dd>{handlerApp.formatCurrency(data.item_cost) || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('itemComponent.itemPrice')}:</dt>
                                <dd>{handlerApp.formatCurrency(data.item_price) || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                        </div>
                    </div>
                    <div className="row np-row">
                        <div className="col-md-12 mb-2">
                            <dt>{t('Description')}</dt>
                            <dd>{data.item_description || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data[prefix+"_modified_by"] !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data[prefix+"_modified_at"] !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemsView;