import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const ValidatePassword = ({passwordValue}) => {
    const [t, i18n] = useTranslation("global");
    const [mayus, setMayus] = useState(false);
    const [minus, setMinus] = useState(false);
    const [nums, setNums] = useState(false);
    const [character, setCharacter] = useState(false);
    const [minLong, setMinLong] = useState(false);
    // const minus, num, charater;
    
    useEffect(() => {
        setMayus(!!passwordValue.match(/[A-Z]/));
        setMinus(!!passwordValue.match(/[a-z]/));
        setNums(!!passwordValue.match(/[0-9]/));
        setCharacter(!!passwordValue.match(/[\W_]/));
        setMinLong(passwordValue.length > 7);

    }, [passwordValue]);

    
    return (
        <>
            <label className="simple-text"> {t('validationPassword.instruction')} </label>
            <ul style={{marginBottom: '0vh'}} className="list-requeriment-password">
                <li className={mayus && "text-green"}>
                    {t('validationPassword.uppercase')}
                </li>
                <li className={minus && "text-green"}>
                    {t('validationPassword.lowercase')}
                </li>
                <li className={nums && "text-green"}>
                    {t('validationPassword.number')}
                </li>
                <li className={character && "text-green"}>
                    {t('validationPassword.specialCharacter')}
                </li>
                <li className={minLong && "text-green"}>
                    {t('validationPassword.minimumCharacters')}
                </li>
                
            </ul>
        </>
    )
}


export { ValidatePassword }