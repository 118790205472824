import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useNavigate, useParams } from "react-router-dom";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { useTranslation } from "react-i18next";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";
import { useForm, Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

const PrintersForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = 'configuration/printers';
    // const endpointAlarms = "orders/alarmsConfiguration";
    const api = useFetch();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const profile = handlerApp.getProfile()

    const nav = useNavigate();
    const { id } = useParams();
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState(t('printersComponent.create'));
    const [description, setDescription] = useState(t(''));
    const [alarmPickUp, setAlarmPickUp] = useState(moment.parseZone('00:00', "mm:ss"));
    const [normalLimit, setNormalLimit] = useState(moment.parseZone('00:00', "mm:ss"));
    const [nearDelayLimit, setNearDelayLimit] = useState(moment.parseZone('00:00', "mm:ss"));
    const [timeDelay, setTimeDelay] = useState(moment.parseZone('00:00', "mm:ss"));
    const [disableSelectCompany, setDisableSelectCompany] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationSelected, setLocationSelected] = useState();

    //Permisos
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setTitle(t('printersComponent.create'));
        setDescription(t('description.printers_create'));
        if (id !== undefined) {
            setTitle(t('printersComponent.updatePrinter'));
            setDescription(t('description.printers_update'))
        }
    }, [t])

    const {modeTheme} = useContext(AppContext);

    const darkTheme = createTheme('pedro',{
        palette: {
          mode: 'dark',
          primary: {
            main: '#90caf9',
          },
          secondary: {
            main: '#f48fb1',
          },
          background: {
            default: '#303030',
            paper: '#424241',
          },
          text: {
            primary: '#ffffff',
            secondary: '#bfbece',
          },
        },
      }, 'dark');

    // location
    const { location,companies } = useContext(LocationContext);

    useEffect(() => {
        if (companies.length > 0) {
            const transformedCompanies = companies.map(company => ({
                code: company.company_id,
                name: company.company_name
            }));
            setCompaniesOptions(transformedCompanies)
        }
    }, [companies])

    // Companies
    const [companiesOptions, setCompaniesOptions] = useState([]);

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined) {
            sendRequest();
            setDisableSelectCompany(true);
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    useEffect(() => {
      if (profile > 2) {
        getParamsUpdate()
      }
    }, [profile])
    

    const [formData, setFormData] = useState({
        company_id: '',
        location_id: '',
        printer_name: '',
        print_pickup: 0,
        print_time_limit_normal: 0,
        print_time_limit_near_delay: 0,
        print_time_delay: 0,
    });

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    if (profile < 2) {
                        getLocations(data.company_id);
                    }
                    setLocationSelected(data.location_id)
                    setDisableButton(false)
                } else {
                    handlerApp.handlerResponse(response);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint,{
            action: "getParamsUpdate"
        }).then(response => {
                if (response.success) {
                    setLocations(handlerApp.getResultFromObject(response.data, 'locations'));
                } else {
                    handlerApp.handlerResponse(response);
                }
                handlerApp.setLoader(false);
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });

        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitLocal = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData, location_id: locationSelected == null ? location : locationSelected
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                } else {
                    handlerApp.handlerResponse(response);
                }
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData, location_id: locationSelected == null ? location : locationSelected
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (alarmPickUp?.hasOwnProperty('_d')) {
            setFormData({ ...formData, print_pickup: hourToMin(alarmPickUp?.format('mm:ss')) });
        }
    }, [alarmPickUp]);

    useEffect(() => {
        if (normalLimit?.hasOwnProperty('_d')) {
            setFormData({ ...formData, print_time_limit_normal: hourToMin(normalLimit?.format('mm:ss')) });
        }
    }, [normalLimit]);

    useEffect(() => {
        if (nearDelayLimit?.hasOwnProperty('_d')) {
            setFormData({ ...formData, print_time_limit_near_delay: hourToMin(nearDelayLimit?.format('mm:ss')) });
        }
    }, [nearDelayLimit]);

    useEffect(() => {
        if (timeDelay?.hasOwnProperty('_d')) {
            setFormData({ ...formData, print_time_delay: hourToMin(timeDelay?.format('mm:ss')) });
        }
    }, [timeDelay]);

    const minToHour =(totalSeconds)=>{
        const hours = Math.floor(totalSeconds / 60);
        const minutes = totalSeconds % 60;

        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        return formattedTime
    }

    function hourToMin (tiempo) {
        const [horas, minutos] = tiempo.split(":").map(Number);
        const totalMinutos = horas * 60 + minutos;
        
        return totalMinutos;
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.print_pickup != null) {
            setAlarmPickUp(moment.parseZone(minToHour(d.print_pickup), "mm:ss"));
        }
        if (d.print_time_limit_normal != null) {
            setNormalLimit(moment.parseZone(minToHour(d.print_time_limit_normal), "mm:ss"));
        }
        if (d.print_time_limit_near_delay != null) {
            setNearDelayLimit(moment.parseZone(minToHour(d.print_time_limit_near_delay), "mm:ss"));
        }
        if (d.print_time_delay != null) {
            setTimeDelay(moment.parseZone(minToHour(d.print_time_delay), "mm:ss"));
        }
        setFormData(d);
    };

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    };

    const handleSelectCompany = (val) => {
        setFormData(prevState => ({
            ...prevState,
            location_id: ''

        }));
        setLocations([]);
        getLocations(val)
    }

    const getLocations = (company) => {
        if ((company !== undefined && company !== "")) {
            handlerApp.setLoader(true);
            api.get("administration/users", {
                action: "getLocation",
                company: company,

            })
                .then(response => {
                    if (response.success) {
                        setLocations(response.data);
                        handlerApp.setLoader(false);
                        
                    } else {
                        handlerApp.setLoader(false);
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(error);
                })
        }
        
    }

    const handleSelectlocation = (val) => {
        setLocationSelected(val)
            
    }
    


    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description} </p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="row">
                                {profile <= 2 && (
                                    <div className="col-md-6 mb-2">
                                        <BoldSelectValidated
                                            title={t('companyComponent.company') + " *"}
                                            value={formData.company_id}
                                            name="company_id"
                                            options={companiesOptions}
                                            onChange={handleForm}
                                            register={register}
                                            errors={errors}
                                            required={true}
                                            disable={disableSelectCompany}
                                            dependency={handleSelectCompany}
                                        />
                                    </div>
                                )}
                                <div className="col-md-6 mb-2">
                                    <BoldSelectValidated
                                        title={t('locationComponent.location') + " *"}
                                        value={formData.location_id}
                                        name="location_id"
                                        options={locations}
                                        onChange={handleForm}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                        disable={formData.printer_name == 'Default'}
                                        dependency={handleSelectlocation}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <TextField
                                        label={t('printersComponent.printerName')+" *"} variant="standard" size="small"
                                        {...register("printer_name", { required: true, maxLength: 80 })}
                                        value={formData.printer_name}
                                        onChange={handleForm}
                                        error={errors.printer_name != null}
                                        helperText={
                                            errors.printer_name?.type === 'required' ? t('validationErrorMessages.required') :
                                                errors.printer_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                        }
                                        disabled={formData.printer_name == 'Default' && true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            {modeTheme ? 
                                <ThemeProvider theme={darkTheme}>
                                    <TimePicker
                                            ampm={false}
                                            openTo="minutes"
                                            views={[ 'minutes', 'seconds']}
                                            format="mm:ss"
                                            label={t('alarmsConfigurationComponent.alarmConfigurationPickup')+" (hh:mm)"}
                                            value={alarmPickUp}
                                            onChange={(val) => setAlarmPickUp(val)}       
                                    />
                                </ThemeProvider>
                            : 
                                <TimePicker
                                    ampm={false}
                                    openTo="minutes"
                                    views={[ 'minutes', 'seconds']}
                                    format="mm:ss"
                                    label={t('alarmsConfigurationComponent.alarmConfigurationPickup')+" (hh:mm)"}
                                    value={alarmPickUp}
                                    onChange={(val) => setAlarmPickUp(val)}
                                    
                                />
                            }
                        <p className="text-gray light medium-text mb-3">{t('printersComponent.pickup')}</p>
                        </div>

                        <div className="col-md-6 mb-2">
                            {modeTheme ? 
                                <ThemeProvider theme={darkTheme}>
                                    <TimePicker
                                        ampm={false}
                                        openTo="minutes"
                                        views={[ 'minutes', 'seconds']}
                                        format="mm:ss"
                                        label={
                                            <>
                                                <span className="green-dot"></span>
                                                {t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNormal')} (hh:mm)
                                            </>
                                        }
                                        value={normalLimit}
                                        onChange={(val) => setNormalLimit(val)}  
                                    />
                                </ThemeProvider>
                            :
                                <TimePicker
                                    ampm={false}
                                    openTo="minutes"
                                    views={[ 'minutes', 'seconds']}
                                    format="mm:ss"
                                    label={
                                        <>
                                            <span className="green-dot"></span>
                                            {t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNormal')} (hh:mm)
                                        </>
                                    }
                                    value={normalLimit}
                                    onChange={(val) => setNormalLimit(val)}  
                                />    
                            }
                        <p className="text-gray light medium-text mb-3">{t('printersComponent.normalTime')}</p>

                        </div>

                        <div className="col-md-6 mb-2">
                            {modeTheme ? 
                                <ThemeProvider theme={darkTheme}>
                                    <TimePicker
                                        ampm={false}
                                        openTo="minutes"
                                        views={[ 'minutes', 'seconds']}
                                        format="mm:ss"
                                        label={
                                            <>
                                                <span className="yellow-dot"></span>
                                                {t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNearDelay')} (hh:mm)
                                            </>
                                        }
                                        value={nearDelayLimit}
                                        onChange={(val) => setNearDelayLimit(val)}  
                                    />
                                </ThemeProvider> 
                            :
                                <TimePicker
                                ampm={false}
                                openTo="minutes"
                                views={[ 'minutes', 'seconds']}
                                format="mm:ss"
                                label={
                                    <>
                                        <span className="yellow-dot"></span>
                                        {t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNearDelay')} (hh:mm)
                                    </>
                                }
                                value={nearDelayLimit}
                                onChange={(val) => setNearDelayLimit(val)}    
                                /> 
                            }   
                    <p className="text-gray light medium-text mb-3">{t('printersComponent.nearDelay')}</p>

                        </div>

                        <div className="col-md-6 mb-2">
                            {modeTheme ? 
                                <ThemeProvider theme={darkTheme}>
                                    <TimePicker
                                        ampm={false}
                                        openTo="minutes"
                                        views={[ 'minutes', 'seconds']}
                                        format="mm:ss"
                                        label={
                                            <>
                                                <span className="red-dot"></span>
                                                {t('alarmsConfigurationComponent.alarmConfigurationTimeDelay')} (hh:mm)
                                            </>
                                        }
                                        value={timeDelay}
                                        onChange={(val) => setTimeDelay(val)}
                                        
                                    />
                                </ThemeProvider> 
                            : 
                                <TimePicker
                                ampm={false}
                                openTo="minutes"
                                views={[ 'minutes', 'seconds']}
                                format="mm:ss"
                                label={
                                    <>
                                        <span className="red-dot"></span>
                                        {t('alarmsConfigurationComponent.alarmConfigurationTimeDelay')} (hh:mm)
                                    </>
                                }
                                value={timeDelay}
                                onChange={(val) => setTimeDelay(val)}
                                />  
                            }   
                    <p className="text-gray light medium-text mb-3">{t('printersComponent.delay')}</p>

                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>

                
            </div>
        </div>
    )
}
export default PrintersForm;