import { createContext, useEffect, useState } from "react";
import { useFetch } from "../hooks/useFecth";
import HandlerApp from "../utils/handlerApp";
import { useTranslation } from "react-i18next";

const LocationContext = createContext();
const LocationContextProvider = ({children}) =>{
    const [t]           = useTranslation('global');

    const [location, setLocation] = useState("");
    const [company, setCompany] = useState("");
    const [companies, setCompanies] = useState([])
    const [locations, setLocations] = useState([])
    const [companyLabel,setCompanyLabel]    = useState(t('globalCompany'));
    const [locationLabel,setLocationLabel]  = useState(t('globalLocation'));
    const api = useFetch();
    const handlerApp = HandlerApp();

    const checkLocations =()=>{
        setLocations([])
        localStorage.setItem("locations", JSON.stringify([]));
        let profile= localStorage.getItem("profile");
        api.get("administration/locations",{
            action: 'checkLocations'
        })
        .then(response =>{
            if(response.success){
                if(profile>2){
                    setLocations(response.data);
                    localStorage.setItem("locations", JSON.stringify(response.data));
                }else{
                    if(company !== ""){
                        setLocations(response.data);
                        localStorage.setItem("locations", JSON.stringify(response.data));
                    }
                }
            }
        })
        .catch(error=>{
            handlerApp.showError("Error #E001");
        })
    }

    const getCompanies = () => {
        setCompanies([]);
        api.get("/administration/companies",{
            action: 'getAllCompanies'
        })
        .then(response =>{
            if(response.success){
                setCompanies(response.data);
            }else{
                setCompanies([]);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error=>{
            handlerApp.showError("E001");
        })
    }

    const setLabelLocation = (id)=>{
        let element = locations.filter(e => e.location_id?.toString() === id?.toString() );
        if(element.length > 0){
            setLocationLabel(element[0]?.location_name);
        }else{
            setLocationLabel(t('globalLocation'))
        }
    };

    const setLabelCompany = (id)=>{
        let element = companies.filter(e => e.company_id?.toString() === id?.toString() );
        if(element.length > 0){
            setCompanyLabel(element[0]?.company_name);
        }else{
            setCompanyLabel(t('globalCompany'))
        }
    };

    useEffect(() => {
        if (company !== '') {
            getLocationsByCompany(company)
        }
    }, [company])
    


    const getLocationsByCompany =(id)=>{
        handlerApp.setLoader(true)

        api.get("get_locations_company/"+id)
        .then(response =>{
            if(response.success){
                setLocations(response.data);
                localStorage.setItem("locations", JSON.stringify(response.data));
            }else{
                handlerApp.handlerResponse(response);
            }
            handlerApp.setLoader(false)
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false)

        })
    }

    return (
        <LocationContext.Provider value={{location, setLocation, locations, setLocations, checkLocations, company, setCompany, companies, getCompanies,setLabelLocation,setLabelCompany,companyLabel,setCompanyLabel,locationLabel,setLocationLabel}}>
            {children}
        </LocationContext.Provider>
    )
}

export { LocationContext, LocationContextProvider };
