
import { TextField } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { LocationContext } from "../../../context/LocationContext";

const PosForms = () => {

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = "configuration/pos";
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]);
    // Permisos
    const handlerApp = HandlerApp();
    const profile = handlerApp.getProfile()
    const [permissions, setPermissions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const { companies } = useContext(LocationContext);

    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('connectedDevicesComponent.createConnectedDevices'));
        setDescription(t("description.connectedDevices_create"));
        if (id !== undefined) {
            setTitle(t('connectedDevicesComponent.updateConnectedDevices'));
            setDescription(t("description.connectedDevices_update"));
        }
    }, [t])

    // utils
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();


    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_id: "",
        pos_serial: "",
        pos_label: "",
        location_id: ""
    });

    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [location, setLocation] = useState([]);
    const [locationSelected, setLocationSelected] = useState();

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                    getLocations(data.company_id)
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            });
    }

    useEffect(() => {
        if (id !== undefined) {
            sendRequest();
        } else {
            handlerApp.setLoader(false);
        }
    }, [])
    

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });

        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitLocal = (data) => {
        let body = { ...data };
        handlerApp.setLoader(true);
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        // seteo imagenes
        let body = { ...data };
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (companies.length > 0) {
            const transformedCompanies = companies.map(company => ({
                code: company.company_id,
                name: company.company_name
            }));
            setCompaniesOptions(transformedCompanies)
        }
    }, [companies])

    const handleSelectCompany = (val) => {
        setFormData(prevState => ({
            ...prevState,
            location_id: ''

        }));
        setLocation([]);
        getLocations(val)
    }

    const getLocations = (company) => {
        if ((company !== undefined && company !== "")) {
            handlerApp.setLoader(true);
            api.get("administration/users", {
                action: "getLocation",
                company: company,

            })
                .then(response => {
                    if (response.success) {
                        setLocation(response.data);
                        handlerApp.setLoader(false);
                        
                    } else {
                        handlerApp.setLoader(false);
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(error);
                })
        }
        
    }
    const handleSelectlocation = (val) => {
        setLocationSelected(val)
    }

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} onKeyDown={handleKeyDow}>
                    <div className="row">
                        {profile <= 2 && (
                            <>
                                <div className="col-md-6 mb-2">
                                    <BoldSelectValidated
                                        title={t('companyComponent.company') + " *"}
                                        value={formData.company_id ?? ''}
                                        name="company_id"
                                        options={companiesOptions}
                                        onChange={handleForm}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                        dependency={handleSelectCompany}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <BoldSelectValidated
                                        title={t('locationComponent.location') + " *"}
                                        value={formData.location_id ?? ''}
                                        name="location_id"
                                        options={location}
                                        onChange={handleForm}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                        dependency={handleSelectlocation}
                                    />
                                </div>
                            </>
                            
                        )}
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('posComponent.serial')+" *"}
                                variant="standard"
                                size="small"
                                {...register("pos_serial", { required: true, maxLength: 120 })}
                                value={formData.pos_serial ?? ''}
                                onChange={handleForm}
                                error={errors.pos_serial != null}
                                helperText={
                                    errors.pos_serial?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.pos_serial?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                                disabled={id !== undefined}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('posComponent.label')+" *"}
                                variant="standard"
                                size="small"
                                {...register("pos_label", { required: true, maxLength: 60 })}
                                value={formData.pos_label ?? ''}
                                onChange={handleForm}
                                error={errors.pos_label != null}
                                helperText={
                                    errors.pos_label?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.pos_label?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 60  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                                disabled={id !== undefined}
                            />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PosForms;