import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import env from "../env-local";
import HandlerApp from "../utils/handlerApp";


export const useFetch = () => {
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const instance = axios.create({
        baseURL: process.env.REACT_APP_URL_BACKEND,
        headers: {
            Authorization: (localStorage.getItem("token")) ? localStorage.getItem("token") : ""
        }
    })

    const logout = () => {
            // Guarda el valor de darkMode
        const darkMode = localStorage.getItem('darkMode');
        // Limpia todo menos darkMode
        localStorage.clear();
        // Restaura el valor de darkMode
        if (darkMode !== null) {
            localStorage.setItem('darkMode', darkMode);
        }
        Swal.fire({
            title: "",
            text: "Session Expired",
            icon: "info"
        });
    }

    const notPermissions = ()=>{
        Swal.fire({
            title: "",
            text: "Not permission",
            icon: "warning"
        });
        handlerApp.setLoader(false);
        nav("/");
    }

    const post = (url, body = {}, params = {}) => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            try{
                instance.post(url,body)
                .then(response=>{
                    if (response.data.success) {
                        resolve(response.data);
                    }else{
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/login");
                        }else if(response.data.code === "0001"){
                            notPermissions();
                        }else {
                            resolve(response.data);
                        }
                    }
                })
                .catch(error=>{
                    if(error.response.status === 500){
                        reject(error);
                    }else{
                        resolve(error.response.data);
                    }
                })
            }catch(error){
                resolve(error);
            }
        });
    }

    const put = (url, body = {},params = {}) => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            try{
                instance.put(url,body)
                .then(response=>{
                    if (response.data.success) {
                        resolve(response.data);
                    }else{
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/login");
                        }else if(response.data.code === "0001"){
                            notPermissions();
                        }else {
                            resolve(response.data);
                        }
                    }
                })
                .catch(error=>{
                    if(error.response.status === 500){
                        reject(error);
                    }else{
                        resolve(error.response.data);
                    }
                })
            }catch(error){
                resolve(error);
            }
        });


    }


    const get = (url, params) => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            try{
                instance.get(url)
                .then(response=>{
                    if (response.data.success) {
                        resolve(response.data);
                    }else{
                        if (response.data.action === "closeSession") {
                            logout()
                            nav("/login");
                        }else if(response.data.code === "0001"){
                            notPermissions();
                        }else {
                            resolve(response.data);
                        }
                    }
                })
                .catch(error=>{
                    if(error.response.status === 500){
                        reject(error);
                    }else{
                        resolve(error.response.data);
                    }
                })
            }catch(error){
                resolve(error);
            }
        });
    }
    const deleteMethod = async (url, body = {}, params="") => {
        params = new URLSearchParams(params).toString();
        url = (params !== "") ? url+"?"+params : url;
        return new Promise((resolve,reject)=>{
            instance.delete(url,{ data: body })
            .then( response =>{
                if (response.data.success) {
                    resolve(response.data);
                }else{
                    if (response.data.action === "closeSession") {
                        logout()
                        nav("/authenticate");
                    }else if(response.data.code === "0001"){
                        notPermissions();
                    }else {
                        resolve(response.data);
                    }
                }
            }).catch(error=>{
                reject(error.response.data);
            })
        });
    }

    return { post, put, get, nav, deleteMethod };
}