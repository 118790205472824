const Widget = (props) => {

    return (
        <div className='widget'>
            <div className={"icon-widget icon-widget-" + props.idWidget}>
                <span className="material-symbols-outlined">{props.icon}</span>
            </div>
            <div className='title-widget'>
                {props.title}
                <div className='title-widget-complement'>{props.value}</div>
            </div>
            <div align="center">
                <hr width="80%" align="center" />
            </div>
            <div className='compare-widget'>
                {props.compareColor === 'green' && <span className='text-green regular'>{props.compareValue}</span>}
                {props.compareColor === 'red' && <span className='text-red regular'>{props.compareValue}</span>}
                {props.compareColor === 'orange' && <span className='text-orange regular'>{props.compareValue}</span>}
                <span className='text-gray light'> {props.compareText}</span>
            </div>
        </div>
    )
}

export default Widget;