import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import IngredientList from "./IngredientList";
import RemovedIngredientList from "./RemovedIngredientList";
import ExtrasList from "./ExtrasList";
import DrinkList from "./DrinkList";
import { useEffect, useState } from "react";
import HandlerApp from "../../../utils/handlerApp";
import notfound from "../../../assets/img/image-not-found.webp"


const OrderDetails = ({ order }) => {
    const handlerApp = HandlerApp();

    const [orderNew, setOrderNew]= useState([]);

    useEffect(() => {
        if (!order.order_detail) return;
    
        const orderFormater = order.order_detail.map((detail) => {
            let updatedDetail = { ...detail }; 
    
            if (updatedDetail.orderdetail_detail.Ingredients.length > 0) {
                const ingredientesAdd1 = updatedDetail.orderdetail_detail.Ingredients.filter((element) => element.item_cant > 1);
                const ingredientesAdd = ingredientesAdd1.map((ingredient)=>{
                    let updatedIngredient = { ...ingredient }; 
                    updatedIngredient.item_cant = updatedIngredient.item_cant-1
                    return updatedIngredient
                })
                const currentExtras = new Set(updatedDetail.orderdetail_detail.Extras.map(JSON.stringify));
                ingredientesAdd.forEach(ingredient => currentExtras.add(JSON.stringify(ingredient)));
                updatedDetail.orderdetail_detail.Extras = Array.from(currentExtras).map(JSON.parse);
            }
            return updatedDetail;
        });
    
        setOrderNew(orderFormater );
    }, [order]);

    return orderNew && orderNew.map((detail, index) => {
        const {
            order_id,
            images,
            product_name,
            orderdetail_quantity,
            orderdetail_detail,
            orderdetail_total
        } = detail;

        return (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} className="detail-order" key={index}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar
                            alt="Remy Sharp"
                            src={images ? images : notfound}
                            style={{ 
                                width: "100px", 
                                height: "100px",
                                marginRight: "20px"
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${product_name} x${orderdetail_quantity}`}
                        secondary={
                            <>
                                <div>{handlerApp.formatCurrency(orderdetail_total)}</div>
                                <ExtrasList extras={orderdetail_detail.Extras} />
                                <DrinkList drinks={orderdetail_detail.drink} />
                                <RemovedIngredientList removedIngredients={orderdetail_detail.removeIngredients} />
                            </>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" className="detail-order-divider"/>
            </List>
        );
    });
}

export default OrderDetails;