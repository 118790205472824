import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import env from "../env-local";
//import HandlerApp from "../utils/handlerApp";
import { collection, getDocs, doc, getDoc, query, addDoc, deleteDoc,updateDoc } from "firebase/firestore";
import { db } from "../firebase/config";


export const useFirebase = () => {
    //const handlerApp = HandlerApp();
    const nav = useNavigate();

    const logout = () => {
        localStorage.clear();
        Swal.fire({
            title: "",
            text: "Session Expired",
            icon: "info"
        });
    }
    
    const post = async (url, body) => {
        const dataRef = collection(db, url);
        try {
            const docRef = await addDoc(dataRef, body);
            return { success: true, id: docRef.id };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    const put = async (url, body, id) => {
        try {
            const docRef = doc(db, url, id);
            await updateDoc(docRef, body);
            return { success: true };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    const deleteMethod = async (url, id) => {
        try {
            const dataRef = doc(db, url, id);
            await deleteDoc(dataRef);
            return { success: true };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }


    const get = async (url, params = null, sql = null) => {
        try {
            if (params != null) {
                const dataRef = doc(db, url, params);
                const response = await getDoc(dataRef);
                if (response.exists()) {
                    return { success: true, data: { ...response.data(), id: response.id } };
                } else {
                    return { success: false, error: 'No such document!' };
                }
            } else {
                const dataRef = collection(db, url);
                const q = sql == null ? dataRef : query(dataRef, sql);
                const response = await getDocs(q);
                const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                return { success: true, data };
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    return { post, put, get, deleteMethod };
}