import ReactECharts from 'echarts-for-react';
import { useTranslation } from "react-i18next";

const BarGraph = ({ dataX, dataY, theme }) => {
  const [t, i18n] = useTranslation('global');


  const option = {
    title: {
      text: t('weekly_income'),
    },
    tooltip: {},
    xAxis: {
      data: dataX,
      axisLabel: {
        rotate: 0,
        interval: 0,
      },
    },
    yAxis: {},
    series: [
      {
        name: t('daily_income'),
        type: 'bar',
        data: dataY,
      },
    ],
  };


  return (
    <ReactECharts option={option} theme={theme} />
  );
};

export default BarGraph;
