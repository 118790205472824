import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import moment from "moment";

const SubscriptionPOSView = () =>{
    
    const endpoint = 'configuration/subscription_pos';
    const [data, setData] = useState([]);
    const api = useFetch();
    const {id} = useParams();
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [t,i18n] = useTranslation("global");
    const [title, setTitle] = useState(t('subscriptionsComponent.subscriptionsView'));    

    useEffect(()=>{
        sendRequest();
    },[]);

    const sendRequest = () =>{
        handlerApp.setLoader(true);
        api.get(endpoint + '/'+ id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let record = response.data[0];
                setData(record);
            }
            else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }
    const back = ()=>{
        nav(-1);
    }
    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <dt>{"ID"}</dt>
                        <dd>{data.subscriptionpos_id}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.location')}</dt>
                        <dd>{data.location_name}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.company')} </dt>
                        <dd>{data.company_name}</dd>
                    </div>                        
                </div>
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.startDate')}</dt>
                        <dd>{moment(data.subscriptionpos_start_date).format("DD/MM/YYYY, HH:mm")}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.endDate')}</dt>
                        <dd>{moment(data.subscriptionpos_end_date).format("DD/MM/YYYY, HH:mm")}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.paymentDate')} </dt>
                        <dd>{moment(data.subscriptionpos_payment_date).format("DD/MM/YYYY, HH:mm")}</dd>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.serial')} </dt>
                        <dd>{data.subscriptionpos_serial}</dd>
                    </div>
                    <div className="col-md-4 mb-2">
                        <dt>{t('subscriptionsComponent.autoRenewal')}</dt>
                        {data.subscriptionpos_auto_renewal == "1"?
                            <dd>{t("yes")}</dd>:
                            <dd>{t("no")}</dd>
                        }
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubscriptionPOSView;