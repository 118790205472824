import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";


const UsersView = ()=>{
    const endpoint = "administration/users";
    const [t,i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [result,setResult] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.person_firstname+' '+data.person_surname);
                setResult(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="form-title">
                    {t("user")}
                </div>

                <div className="row col-11">
                    <div className="col-md-6 mb-2">
                        <dt>{t('user')}</dt>
                        <dd>{result.user_username || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('companyComponent.company')}</dt>
                        <dd>{result.company_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('profile')}</dt>
                        <dd>{result.profile_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t("usersLocationsComponent.userlocation_pincode")}</dt>
                        <dd>{result.userlocation_pincode || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                </div>

                <div className="footer-section-view row"></div>

                <div className="form-title">
                    {t("personComponent.personInformation")}
                </div>

                <div className="row col-11">
                    <div className="col-md-6 mb-2">
                        <dt>{t('name')}:</dt>
                        <dd>{result.person_firstname} {result.person_secondname} {result.person_surname} { result.person_secondsurname}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('personComponent.sex')}</dt>
                        <dd>{result.person_sex || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('email')}</dt>
                        <dd>{result.person_email || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('ext')}:</dt>
                        <dd>{result.person_ext || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('phone')}</dt>
                        <dd>{result.person_phone || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('personComponent.birthdate')}</dt>
                        <dd>{result.person_birthdate || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('occupation')}</dt>
                        <dd>{result.person_occupation || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('personComponent.marital')}</dt>
                        <dd>{result.person_marital_status || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                </div>

                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {result.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(result.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {result.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {result.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {result.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(result.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default UsersView;