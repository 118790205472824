import { AppContextProvider } from "../context/AppContext"
import { LoaderContextProvider } from "../context/LoaderContext";
import { LocationContextProvider } from "../context/LocationContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import UserCompanyPasswordSet from "./pages/UserCompanyPasswordSet"
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import global_es from "../translate/es/global.json";
import global_en from "../translate/en/global.json";
import LanguageDetector from 'i18next-browser-languagedetector';
const App =()=>{
    const opt = {
        // order and from where user language should be detected
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
      
        // cache user language on
        caches: ['localStorage', 'cookie'],
      }
    i18next.use(LanguageDetector).init({
        detection: opt,
        interpolation: {escapeValue: false},
        // lng: "es",
        resources: {
            es: {
                global: global_es
            },
            en: {
                global: global_en
            }
        }
    })

    return(
        <I18nextProvider i18n={i18next}>
            <UserCompanyPasswordSet />
        </I18nextProvider>
    )
}

export default App;