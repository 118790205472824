import { Box, Chip, FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from "react-i18next";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(option, val, theme) {
    return {
      fontWeight:
        val.indexOf(option.code) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const BoldMultiplesSelect = ({title, value, name, options, onChange})=>{
    const [t, i18n] = useTranslation("global");
    const [val, setVal] = useState([]);
    const theme = useTheme();
    useEffect(()=>{
        if(value){
            //console.log(value);
            if(options.length > 0){
                let valueInt = value.map(str => {
                    return parseInt(str, 10);
                  });
                setVal(valueInt);
            }
        }
    },[options])

    const handleChange = (e) => {
        setVal(e.target.value);
    
        onChange(e,name);
    };

    //console.log(val,'soy val');

    return(
        <FormControl className="w-100" variant="standard" size="small">
        {/* <InputLabel id={"label-"+title}>{title}</InputLabel> */}
            <InputLabel id={"label-"+title} title={t("optionalField")}>
                {title}
                {(title === "Ingredientes" || title === "Extras") && (<HelpOutlineIcon 
                    style={{ marginLeft: '5px', verticalAlign: 'middle', cursor: 'pointer' }}
                />)}
            </InputLabel>
        <Select
            labelId={"label-"+title}
            id={"chip-"+title}
            multiple
            value={val}
            onChange={handleChange}
         
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                    options.map(element=>{
                        return selected.map(value=>(value==element.code && <Chip key={element.code} label={element.name}/>))
                    })
                }
                </Box>
            )}
            MenuProps={MenuProps}
        >
            {
            options.map( (option,index) =>
                <MenuItem value={option.code} key={option.name+'-'+index} style={getStyles(option, val, theme)}>{option.name}</MenuItem>
            )
            }
        </Select>
     
    </FormControl>
      
        
    )
}

export default BoldMultiplesSelect;