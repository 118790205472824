import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../utils/handlerApp";
import NoDataTables from "../../noDataTable/NoDataTables";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";

const TransactionsCustomer = () => {
    const endpoint   = 'transactions-customer';
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    const profile    = handlerApp.getProfile()
    const [t]        = useTranslation("global");
    let title        = t('transactionsCustomer.transactions');
    
    const [data, setData] = useState([]);

    const styleCanceled = {
        background: '#d32f2f',
        color: 'white',
        height: '20px',
        width: '70px',
        borderRadius: '12px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };
    const styleSuccess = {
        background: '#56AC40',
        color: 'white',
        height: '20px',
        width: '70px',
        borderRadius: '12px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    //Permisos
    const [permissions, setPermissions] = useState([]);
    const { app, modeTheme } = useContext(AppContext);
    const { company } = useContext(LocationContext);

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint, {company})
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    if (response.message !== "M001") {
                        handlerApp.handlerResponse(response);
                    }
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);

            })
    }
    useEffect(() => {
        sendRequest();
    }, [company]);

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
        }
    }
    const optionRequest = (id, action) => {
        Swal.fire({
            title: action === 'active' ? `${t('activeAlert.activeRecord')}` : `${t('inactiveAlert.inactiveAlert')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: action === 'active' ? "#08a215" : "#be2a2a",
            cancelButtonColor: "#636c74",
            cancelButtonText: `${t('cancel')}`,
            confirmButtonText: action === 'active' ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`
        }).then((result) => {
            if (result.isConfirmed) {
                executeAction(id, action);
            }
        });
    }
    
    const executeAction = (id, action) => {
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                handlerApp.showOk(response);
                sendRequest();
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const columns = [
        {
            id: 'tx_id',
            name: 'ID',
            selector: row => row.tx_id,
            sortable: true,
            width: "60px"

        },
        ...(profile <= 2 ? [
            {
                id: 'company_name',
                name: t('posComponent.company'),
                selector: row => row.company_name,
                sortable: true
            }
        ] : []),
        {
            id: 'tx_transaction_id',
            name: t('transactionsCustomer.transactionId'),
            selector: row => row.tx_transaction_id,
            sortable: true
        },
        {
            id: 'invoice_id',
            name: t('transactionsCustomer.invoiceId'),
            selector: row => row.invoice_id,
            sortable: true
        },
        {
            id: 'tx_description',
            name: t('transactionsCustomer.transactionType'),
            cell: row => {
                if (row.tx_description === 'Charge') {
                    return <div style={styleSuccess}>{t('transactionsCustomer.charge')}</div>;
                } else if (row.tx_description === 'Refund') {
                    return <div style={styleCanceled}>{t('transactionsCustomer.refund')}</div>;
                }
            },
            sortable: true,
        },
        {
            id: 'tx_status_processor',
            name: t('transactionsCustomer.transactionStatus'),
            cell: row => {
                if (row.tx_status_processor === 'succeeded') {
                    return <div style={styleSuccess}>{t('transactionsComponent.success')}</div>;
                } else {
                    return <div style={styleCanceled}>{t('transactionsComponent.fail')}</div>;
                }
            },
            sortable: true,
            width: "135px"
        },
        {
            id: 'tx_method',
            name: t('transactionsCustomer.method'),
            cell: row => row.tx_method,
            sortable: true,
        },
        {
            id: 'tx_net_amount',
            name: t('transactionsCustomer.amount'),
            cell: row => handlerApp.formatCurrency(row.tx_net_amount),
            sortable: true,
        },

        {
            name: t('action'),
            cell: (row) => { 
                return handlerApp.actions(row, permissions, 'tx', handlerAction) 
            },
            ignoreRowClick: true,
            button: true.toString(),
            width: "150px"

        }
    ];

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.tx_id && item.tx_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.tx_transaction_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.invoice_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.tx_status_processor?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.tx_method?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
            || handlerApp.formatCurrency((item.tx_net_amount))?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (

        <div className="app container">
            <div className="title-section-forms">
                <h3>{title}</h3>
                <p>{t("description.transaccionCustomer")} </p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div className="action-buttons">
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables />}
                        //selectableRows
                        
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}
export default TransactionsCustomer;