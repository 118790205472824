import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { set, useForm } from "react-hook-form";
import { AppContext } from "../../../context/AppContext";
import { TextField } from "@mui/material";
import BoldFilesUploadProfile from "../../../utils/BoldFilesUploadProfile";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import InputMask from 'react-input-mask';
import imageNotFound from "../../../assets/img/image-not-found.webp";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InputAdornment from '@mui/material/InputAdornment';



const PlansForm = () => {

    const endpoint = "plans";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('PlansComponent.createPlans');
    const [description, setDescription] = useState('');
    const api = useFetch();
    const nav = useNavigate();
    // const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    // const { app } = useContext(AppContext);
    const { id } = useParams();
    // const [images, setImages] = useState([]);
    const [identificationType, setIdentificationType] = useState([]);
    const [formData, setFormData] = useState({
        subscription_name: "",
        subscription_price: "",
        subscription_type: "",
        subscription_description: "",
        subscription_life: "",
    });

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    // permisos
    // const [permissions, setPermissions] = useState([]);
    // const [menus, setMenus] = useState([]);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setTitle(t("PlansComponent.createPlans"));
        setDescription(t("description.plan_create"));
        if (id !== undefined) {
            setTitle(t('PlansComponent.updatePlans'));
            setDescription(t("description.plan_update"));
        }
    }, [t])

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    const type = {
                        subscription_type: {
                            "LOCATION": "LOCATION",
                            "LOCATION FREE": "LOCATION FREE",
                            "POS": "POS",
                            "POS FREE": "POS FREE",
                        }
                    };
                    setIdentificationType(type.subscription_type)
                    //setIdentificationType(handlerApp.getResultFromObject(response.data, "subscription_type"));

                    if (id !== undefined) {
                        setTitle('PlansComponent.updatePlans')
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }, [])


    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        let value = e.target.value;
    
        const regex = /^\d+(\.\d{0,2})?$/;

        const isInputNumber = key === "subscription_price" || key === "subscription_life"

        const updateFormData = (key, value) => {
            setFormData(prevState => ({
              ...prevState,
              [key]: value,
            }));
        
            setValue(key, value);
        
            if (dependency) {
              dependency(value);
            }
        };
    
        
        if (!isInputNumber || (isInputNumber && (regex.test(value) || value === ''))) {
            updateFormData(key, value);
        }
    }
    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    setDisableButton(false)
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.showError(response.message);

                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData
        }

        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const optionsArray = Object.entries(identificationType).map(([code, name]) => ({ code, name }));


    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
                <p>{description}</p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t("name")+" *"} variant="standard" size="small"
                                {...register("subscription_name", { required: true, maxLength: 30 })}
                                value={formData.subscription_name}
                                onChange={handleForm}
                                error={errors.subscription_name != null}
                                helperText={
                                    errors.subscription_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.subscription_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                                        <TextField
                                            label={t('PlansComponent.plansAmount')+" *"}
                                            variant="standard"
                                            size="small"
                                            {...register("subscription_price", {
                                                required: true,
                                                min: 0,
                                                max: 2147483647
                                            })}
                                            value={formData.subscription_price}
                                            onChange={handleForm}
                                            error={errors.subscription_price != null}
                                            type="number"
                                            multiline={true}
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: 2147483647
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon />

                                                    </InputAdornment>
                                                )
                                            }}
                                            helperText={
                                                errors.subscription_price?.type === 'required'
                                                    ? t('validationErrorMessages.required')
                                                    : errors.subscription_price?.type === 'min'
                                                        ? `${t('validationErrorMessages.minNumber')} 0 `
                                                        : errors.subscription_price?.type === 'max'
                                                            ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                            : ""
                                            }
                                        />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('PlansComponent.type')+" *"}
                                value={formData.subscription_type}
                                name="subscription_type"
                                onChange={handleForm}
                                options={optionsArray}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField type="number" multiline={true}
                                label={t('PlansComponent.plansLife')+" *"} variant="standard" size="small"
                                {...register("subscription_life", { required: true})}
                                value={formData.subscription_life}
                                onChange={handleForm}
                                error={errors.subscription_life != null}
                                helperText={
                                    errors.subscription_life?.type === 'required' ? t('validationErrorMessages.required') : ""
                                }
                            />

                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField className="bold-text-field-material" name="subscription_description" value={formData.subscription_description} onChange={handleForm} multiline rows={3} label={t('PlansComponent.plansDescription')} size="small" />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PlansForm;