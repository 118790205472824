import { useEffect, useState } from 'react';
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, handleClose, handleConfirm, actionType }) => {
    const [t, i18n] = useTranslation('global');
    const [inputValue, setInputValue] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);
    const [value, setValue] = useState('');
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        setValue('');
        if (actionType === 'REFUND') {
            setValue(t('transactionsComponent.refund'));
        }
    }, [actionType]);

    // Función para manejar el cambio en el campo de texto
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    // Función para manejar la confirmación del usuario
    const handleConfirmation = () => {
        if (isConfirming) {
            // Verifica si la palabra ingresada coincide con la acción deseada
            if (inputValue.trim() === actionType) {
                // Llama a la función de confirmación pasada como prop
                handleConfirm();
                // Cierra el modal
                handleClose();
            } else {
                // Si la palabra no coincide, muestra un texto de error
                setErrorText(`${t('transactionsComponent.errorWord')} '${actionType}' ${t('transactionsComponent.errorWord2')}`);
            }
        } else {
            // Si no se está confirmando, establece el estado en confirmación
            setIsConfirming(true);
        }
    };

    const handleCancel = () => {
        setIsConfirming(false);
        setErrorText('');
        setInputValue('')
        handleClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle sx={{ textAlign: 'center' }}>{t('transactionsComponent.confirm')}</DialogTitle>
            <DialogContent sx={{ textAlign: 'center', paddingBottom: 0 }}>
                {!isConfirming ? (
                    <>
                        <p>{t('transactionsComponent.youAreAbout')} {value}</p>
                        <p>{t('transactionsComponent.doYouWant')}</p>
                    </>
                ) : (
                    <>
                        <p>{t('transactionsComponent.enterTheWord')} <strong>{actionType}</strong> {t('transactionsComponent.toConfirm')}</p>
                        <TextField
                            type="text"
                            label={t('transactionsComponent.confirmationWord')}
                            variant="standard"
                            size="small"
                            value={inputValue}
                            onChange={handleInputChange}
                            error={!!errorText}
                            helperText={errorText}
                            autoComplete="off"
                            onCopy={(e) => e.preventDefault()}  // Deshabilitar copiar
                            onCut={(e) => e.preventDefault()}   // Deshabilitar cortar
                            onPaste={(e) => e.preventDefault()} // Deshabilitar pegar
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button sx={{ textTransform: 'none' }} className="bold-btn bold-btn-light" onClick={handleCancel}>{t('cancel')}</Button>
                <Button sx={{ textTransform: 'none' }} className="bold-btn bold-btn-dark text-light" onClick={handleConfirmation}>{isConfirming ? t('transactionsComponent.confirm') : t('transactionsComponent.continue')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
