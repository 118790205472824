
import { TextField } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import HandlerApp from "../../../utils/handlerApp";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MeasurementIcon from '@mui/icons-material/AttachMoney';
import { LocationContext } from "../../../context/LocationContext";

const ProductsPizzaConfigurationForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = "configuration/pizzaconfiguration";
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [disableSelectCompany, setDisableSelectCompany] = useState(false);
    // Permisos
    const handlerApp = HandlerApp();
    const profile = handlerApp.getProfile()
    const [permissions, setPermissions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const { app } = useContext(AppContext);
    const { companies } = useContext(LocationContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('pizzaConfigurationComponent.createPizzaConfiguration'));
        setDescription(t('description.sizePizza_create'));
        if (id !== undefined) {
            setTitle(t('pizzaConfigurationComponent.updatePizzaConfiguration'));
            setDescription(t('description.sizePizza_update'));
        }
    }, [t])

    // utils
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();

    // estado inicial de formulario
    const [formData, setFormData] = useState({
        company_id: "",
        productpizza_size_name: "",
        productpizza_diametter: "",
        productpizza_slices: "",

    });

    useEffect(() => {
        if (companies.length > 0) {
            const transformedCompanies = companies.map(company => ({
                code: company.company_id,
                name: company.company_name
            }));
            setCompaniesOptions(transformedCompanies)
        }   
      
    }, [companies])

    // Companies
    const [companiesOptions, setCompaniesOptions] = useState([]);

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined) {
            sendRequest();
            setDisableSelectCompany(true);
        } else {
            handlerApp.setLoader(false);
        }
    }, [])


    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        let value = e.target.value;
    
        const regex = /^\d+(\.\d{0,2})?$/;

        const isInputNumber = key === "productpizza_diametter" || key === "productpizza_slices"
        const updateFormData = (key, value) => {
            setFormData(prevState => ({
              ...prevState,
              [key]: value,
            }));
        
            setValue(key, value);
        
            if (dependency) {
              dependency(value);
            }
        };
    
        
        if (!isInputNumber || (isInputNumber && (regex.test(value) || value === ''))) {
            updateFormData(key, value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitLocal = (data) => {
        // e.preventDefault();
        handlerApp.setLoader(true);
        api.post(endpoint, formData)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (data) => {
        // e.preventDefault();
        handlerApp.setLoader(true);
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, formData)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>

            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        {profile <= 2 &&(
                            <div className="col-md-6 mb-2">
                                <BoldSelectValidated
                                    title={t('companyComponent.company') + " *"}
                                    value={formData.company_id ?? ''}
                                    name="company_id"
                                    options={companiesOptions}
                                    onChange={handleForm}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    disable={disableSelectCompany}
                                />
                            </div>
                        )}
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('pizzaConfigurationComponent.sizeName')+" *"} variant="standard" size="small"
                                {...register("productpizza_size_name", { required: true, maxLength: 20 })}
                                value={formData.productpizza_size_name ?? ''}
                                onChange={handleForm}
                                error={errors.productpizza_size_name != null}
                                helperText={
                                    errors.productpizza_size_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.productpizza_size_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 20 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('pizzaConfigurationComponent.diametter')+" ("+t('sizes.inches')+")"+" *"}
                                variant="standard"
                                size="small"
                                {...register("productpizza_diametter", {
                                    required: true,
                                    min: 0,
                                    max: 100
                                })}
                                value={formData.productpizza_diametter ?? ''}
                                onChange={handleForm}
                                error={errors.productpizza_diametter != null}
                                type="number"
                                multiline={true}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100
                                    }
                                }}
                                helperText={
                                    errors.productpizza_diametter?.type === 'required'
                                        ? t('validationErrorMessages.required')
                                        : errors.productpizza_diametter?.type === 'min'
                                            ? `${t('validationErrorMessages.minNumber')} 0 `
                                            : errors.productpizza_diametter?.type === 'max'
                                                ? `${t('validationErrorMessages.MaxNumber')} 100 `
                                                : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('pizzaConfigurationComponent.slices')+" *"}
                                variant="standard"
                                size="small"
                                {...register("productpizza_slices", {
                                    required: true,
                                    min: 0,
                                    max: 100
                                })}
                                value={formData.productpizza_slices ?? ''}
                                onChange={handleForm}
                                error={errors.productpizza_slices != null}
                                type="number"
                                multiline={true}
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                        max: 100
                                    }
                                }}
                                helperText={
                                    errors.productpizza_slices?.type === 'required'
                                        ? t('validationErrorMessages.required')
                                        : errors.productpizza_slices?.type === 'min'
                                            ? `${t('validationErrorMessages.minNumber')} 0 `
                                            : errors.productpizza_slices?.type === 'max'
                                                ? `${t('validationErrorMessages.MaxNumber')} 100 `
                                                : ""
                                }
                            />
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProductsPizzaConfigurationForm;