import { createContext, useEffect, useState } from "react";

const AppContext = createContext();
const AppContextProvider = ({ children }) => {

    const [app,setApp] = useState({
        permissions: [],
        language: 'EN',
        currency: 'USD',
    });
    const [modeTheme,setModeTheme] = useState(localStorage.getItem('darkMode') === 'dark');

    return (
        <AppContext.Provider value={{app,setApp,modeTheme,setModeTheme}}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContext, AppContextProvider };