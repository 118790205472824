import { Alert, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/css/login.css"
import logo from "../../assets/img/bravus-azul-rojo.png";
import logoNegativo from "../../assets/img/Logo-principal-negativo.png";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { AppContext } from "../../context/AppContext";
import { ValidatePassword } from "../molecules/ValidatePassword";

const UserCompanyPasswordSet = () => {
    const { register, handleSubmit, formState: { errors }, setValue ,control } = useForm();
    const [t,i18n] = useTranslation("global");
    const [userForm, setUserForm] = useState({
        fpass: "",
        frpass: ""
    });
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const {identificator} = useParams();

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        toggleDarkMode();
    }, []);

    const toggleDarkMode = (toggleMode = null) => {
        const htmlElement = document.documentElement;
        let darkMode = localStorage.getItem('darkMode');

        if(toggleMode){
            darkMode = darkMode === 'dark' ? '' : 'dark';
        }
        
        
        if (darkMode === 'dark') {
            htmlElement.classList.add('dark');
            setIsDarkMode(true);
            localStorage.setItem('darkMode', 'dark');
        } else if (darkMode === '') {
            htmlElement.classList.remove('dark');
            setIsDarkMode(false);
            localStorage.setItem('darkMode', '');
        } else {
            const themeUser = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (themeUser) {
                htmlElement.classList.add('dark');
                setIsDarkMode(true);
                localStorage.setItem('darkMode', 'dark');
            } else {
                htmlElement.classList.remove('dark');
                setIsDarkMode(false);
                localStorage.setItem('darkMode', '');
            }
        }
    }

    const handleForm = (e) => {
        let key = e.target.name;
        setUserForm({
            ...userForm,
            [key]: e.target.value
        });
        setError();
    }
    
    const nav = useNavigate();
    
    const handleFormLogin = (data) => {
        if(userForm.fpass === userForm.frpass){
            setLoader(true);
            let fpass = btoa(unescape(encodeURIComponent(btoa(unescape(encodeURIComponent(userForm.fpass))))));
            axios.post(process.env.REACT_APP_URL_BACKEND + '/set_main_password/company/'+identificator, {
                fpass: fpass
            }).then(response => {
                if(response.data.success){
                    Swal.fire({
                        title: '',
                        text: t(response.data.message),
                        icon: 'success'
                    });
                    nav("/login");
                }else{
                    Swal.fire({
                        title: '',
                        text: t(response.data.message),
                        icon: 'info'
                    });
                }
                setLoader(false);
            }).catch(error => {  
                setLoader(false); 
                setError(t(error.response.data.message));
            })
        }
    }
    
    return (
        <>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="dark-mode-toggle">
                        <button style={{background: 'transparent', borderColor:'transparent',}} onClick={()=>toggleDarkMode(true)} className="">
                            {isDarkMode ? <><span className="material-symbols-outlined" >light_mode</span><p>{t('toggleDarkMode.Light')}</p></>
                            :<><span className="material-symbols-outlined" >dark_mode</span><p>{t('toggleDarkMode.Dark')}</p></>}   
                        </button>
                    </div>
                    <div className="section-login login" style={{ maxWidth: "500px", width: "70%" }} >
                        { isDarkMode ? <img src={logoNegativo} alt="logo-brava" className="logo-login logo-l"/> :
                         <img src={logo} alt="logo-brava" className="logo-login logo-l"/>}
                        <form onSubmit={handleSubmit(handleFormLogin)}>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <TextField                            
                                        label={t('password')}  variant="standard" size="small" type="password"
                                        {...register("fpass", { 
                                            required: true, 
                                            maxLength: 80, 
                                            pattern: /^(?=.*\d)(?=.*[a-záéíóúüñ])(?=.*[A-ZÁÉÍÓÚÜÑ])(?=.*[\W_]).{8,}$/ , //Formato Regex para válidar que contenga mayúsculas, minúsculas, números, signos y minimo 8 caracteres
                                            validate: (value) => value === userForm.frpass 
                                        })}
                                        value={userForm.fpass}
                                        onChange={handleForm}
                                        error={errors.fpass!= null}
                                        helperText=  {
                                            errors.fpass?.type === 'required' ? t('validationErrorMessages.required') :
                                            errors.fpass?.type === 'maxLength' ? t('validationErrorMessages.long')+ ' 80 ' + t('validationErrorMessages.longSuffix') : 
                                            errors.fpass?.type === 'validate' ? t("validationErrorMessages.dontMatchPassword") :
                                            errors.fpass?.type === 'pattern' ? <ValidatePassword passwordValue={userForm.fpass}/> : ""
                                        }
                                        />
                                </div>
                                <div className="col-md-12 mb-4">
                                    <TextField                            
                                        label={t('re-password')}  variant="standard" size="small" type="password"
                                        {...register("frpass", { 
                                            required: true, 
                                            maxLength: 80, 
                                            pattern: /^(?=.*\d)(?=.*[a-záéíóúüñ])(?=.*[A-ZÁÉÍÓÚÜÑ])(?=.*[\W_]).{8,}$/ ,//Formato Regex para válidar que contenga mayúsculas, minúsculas, números, signos y minimo 8 caracteres 
                                            validate: (value) => value === userForm.fpass 
                                        })}
                                        value={userForm.frpass}
                                        onChange={handleForm}
                                        error={errors.frpass!= null}
                                        helperText=  {
                                            errors.frpass?.type === 'required' ?  t('validationErrorMessages.required') :
                                            errors.frpass?.type === 'maxLength' ? t('validationErrorMessages.long')+' 80 '+t('validationErrorMessages.longSuffix') : 
                                            errors.frpass?.type === 'validate' ? t("validationErrorMessages.dontMatchPassword"): ""
                                        }
                                        />
                                </div>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <center>
                                <Button className="bold-btn-dark" type="submit">{t('submit')}</Button>
                            </center>
                        </form>
                        
                    </div>
                </div>
            </div>
            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </>
    )
}

export default UserCompanyPasswordSet;