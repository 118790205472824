import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCD5R_m44CNngEI0cU7z1_4S0rZgn81VAs",
  authDomain: "bravus-pos.firebaseapp.com",
  projectId: "bravus-pos",
  storageBucket: "bravus-pos.appspot.com",
  messagingSenderId: "62651213414",
  appId: "1:62651213414:web:1e8c0d45789a4f4b086fa7",
  measurementId: "G-MDTY4C34HB"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);