import { useTranslation } from "react-i18next";

const Footer = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <div className="footer-branch d-flex justify-content-between">
                <span className="light text-gray">{t('createdBy')} <a className="company-branch-link" href="https://posbrava.com/" target="_blank">Brava POS</a> © 2023
                </span>
                <ul className="important-links footerLicense">
                    <li><a href="https://posbrava.com/" target="_blank"><span className="light text-gray footer-branch">{t('license')}</span></a></li>
                    <li><a href="https://posbrava.com/" target="_blank"><span className="light text-gray footer-branch">{t('company')}</span></a></li>
                </ul>
            </div>
        </>
    )
}

export default Footer;