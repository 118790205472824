import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const LocationsView = ()=>{
    const endpoint = "administration/locations";
    const [t,i18n] = useTranslation("global");
    const [showPassword, setShowPassword] = useState(false);
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [location,setLocation] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.location_name);
                setLocation(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return(
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.location')}:</dt>
                        <dd>{location.location_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('companyComponent.company')}</dt>
                        <dd>{location.company_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.locationName')}</dt>
                        <dd>{location.location_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.manager')}</dt>
                        <dd>{location.location_manager_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('country')}</dt>
                        <dd>{i18n.language === 'es' ? location.country_name_spanish : location.country_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('state')}</dt>
                        <dd>{location.state_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('city')}</dt>
                        <dd>{location.city_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.timeZone')}</dt>
                        <dd>{location.location_time_zone}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('address')}</dt>
                        <dd>{location.location_address || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('zipCode')}</dt>
                        <dd>{location.location_zipcode || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {location.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(location.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {location.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {location.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {location.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(location.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default LocationsView;