import { useState } from 'react';
import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form"; // Importa useForm para utilizar watch

const ChangePasswordModal = ({ isOpen, handleClose, handleUpdatePassword, handleFormPassword, visibilityPassword, showPassword, passwordError, repeatPasswordError, handleClickShowPassword }) => {
    const [t, i18n] = useTranslation('global');

    const { register, formState: { errors } } = useForm();
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);

    useEffect(() => {
        if (visibilityPassword.password === '' || visibilityPassword.repeatPassword === '') {
            setIsPasswordEmpty(true);
        } else {
            setIsPasswordEmpty(false);
        }
    }, [visibilityPassword.password, visibilityPassword.repeatPassword]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle sx={{ textAlign: 'center' }}>{t('changePassword')}</DialogTitle>
            <DialogContent sx={{ textAlign: 'center', paddingBottom: 0}}>
                <form onSubmit={handleUpdatePassword}>
                    <TextField
                        {...register("password", {
                            required: true,
                            validate: (value) => value === visibilityPassword.repeatPassword
                        })}
                        type={showPassword.password ? "password" : "text"}
                        name="password"
                        onChange={handleFormPassword}
                        value={visibilityPassword.password}
                        label={t('password')}
                        variant="standard"
                        size="small"
                        error={!!passwordError}
                        helperText={passwordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('password')}
                                    >
                                        {showPassword.password ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <TextField
                        {...register("repeatPassword", {
                            required: true,
                            validate: (value) => value === visibilityPassword.password
                        })}
                        type={showPassword.repeatPassword ? "password" : "text"}
                        name="repeatPassword"
                        onChange={handleFormPassword}
                        value={visibilityPassword.repeatPassword}
                        label={t('repeatPassword')}
                        variant="standard"
                        size="small"
                        error={!!repeatPasswordError}
                        helperText={repeatPasswordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleClickShowPassword('repeatPassword')}
                                    >
                                        {showPassword.repeatPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </form>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button sx={{ textTransform: 'none' }} className="bold-btn bold-btn-light" onClick={handleClose}>{t('cancel')}</Button>
                <Button sx={{ textTransform: 'none' }} className="bold-btn bold-btn-dark text-light" type="submit" onClick={handleUpdatePassword} disabled={isPasswordEmpty}>{t('update')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePasswordModal;