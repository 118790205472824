import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { AppContext } from "../../../context/AppContext";

const TaxesGroupView = () =>{
    
    const endpoint = 'web/configuration/taxes';
    const [data, setData] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const api = useFetch();
    const {id} = useParams();
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [t,i18n] = useTranslation("global");
    const [title, setTitle] = useState('');  
    const {modeTheme} = useContext(AppContext);  

    useEffect(()=>{
        sendRequest();
    },[]);

    const sendRequest = () =>{
        handlerApp.setLoader(true);
        api.get(endpoint + '/'+ id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let record = response.data[0];
                setData(record);
                setTaxes(record.taxes);
            }
            else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }
    const back = ()=>{
        nav(-1);
    }
   
    const columns = [
        {
            id: 'tax_name',
            name: t('taxesComponent.tax_name'),
            selector: row => row.tax_name,
            sortable: true,
        },
        {
            id: 'tax_percentage',
            name: t('taxesComponent.tax_percentage'),
            selector: row => handlerApp.formatPercent(row.tax_percentage),
            sortable: true,
        },
    ]
    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-lg-start align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{t('description.taxes_view')}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row col-10">
                <div className="col-md-6 mb-2">
                        <dt>{t('taxesComponent.taxgroup_name')}</dt>
                        <dd>{data.taxgroup_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.location')}</dt>
                        <dd>{data.location_name}</dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <DataTable
                            columns={columns}
                            data= {taxes}
                            // pagination
                            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            theme={modeTheme ? "solarized" : ""}      
                        />
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TaxesGroupView;