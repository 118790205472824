import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../../../assets/css/BoldInputPhone.css";
import "../../../assets/css/DarkBoldInputPhone.css";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

/**
 * 
 * @param name Nombre de campo - key
 * @param label Etiqueta a mostrar del campo 
 * @param register register de react-hook-form
 * @param errors manejador de errores
 * @param onChange callback - funcion ejecutada por los cambios en el componenete, retorna (value, name)
 * @param data value - valor por defecto del componente
 * @author Daniel Bolivar - Github debb94 - daniel.bolivar.freelance@gmail.com
 * @since 2024-07-11
 * @version 1.0
 */
const BoldInputPhone = ({name, label, register, errors, onChange, data})=>{
    const [t] = useTranslation("global");
    const [openOptions,setOpenOptions] = useState(false);
    const [value, setValue] = useState(data || "");
    const [country, setCountry] = useState(data.substring(0,3) === "+57" ? "+57" : "+1");
    const [image, setImage] = useState();

    useEffect(()=>{
        let prefix = "";
        prefix = data.substring(0,3);
        if(prefix === "+57"){
            setCountry("+57");
            setValue(data);
        }
        prefix = data.substring(0,2);
        if(prefix === "+1"){
            setCountry("+1");
            setValue(data);
        }
    },[data]);

    useEffect(()=>{
        if(country){
            let img = options.filter(e=> e.value == country)
            setImage(img[0].image);
        }
        if(country === "+57" && value.substring(0,3) !== "+57"){
            setValue(country+value.substring(2));
        }
        if(country === "+1" && value.substring(0,2) !== "+1"){
            setValue(country+value.substring(3));
        }
    },[country])

    useEffect(()=>{
        onChange(value, name);
    },[value])


    const options = [
        {
            value: "+1",
            label: "Estados Unidos",
            image: "/assets/countries/Estados-Unidos.png"
        },
        {
            value: "+57",
            label: "Colombia",
            image: "/assets/countries/Colombia.png"
        },
    ]

    return(
        <div className="container-bold-input-phone">
            <div className="container-bold-selector">
                <div className="bold-selector">
                    <div className="bold-select-selected" onClick={()=>{setOpenOptions(!openOptions)}}>
                        <img src={image}/>
                        <div style={{paddingTop: 5}}>
                            { (!openOptions) ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                        </div>
                    </div>
                    <div className={ (openOptions) ? "bold-selector-options show" : "bold-selector-options"}>
                        {options.map( (e,index) =>{
                            return <div className="bold-selector-option" key={index} onClick={()=>{
                                setCountry(e.value);
                                setOpenOptions(!openOptions);
                            }}>
                                <img src={e.image}/>
                                <label>{e.label}</label>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <TextField label={label} variant="standard"  size="small" className="bold-input-phone"
                {...register(name,{ required: true, maxLength: 13, minLength: 12})}
                onChange={(e)=>{
                    let len = e.target.value.length;
                    if(len >= country.length && /^\+[1-9]\d{0,13}$/.test(e.target.value)){
                        setValue(e.target.value);
                    }
                }}
                error={errors!= null}
                helperText = {
                    errors?.type === 'required' ? t("validationErrorMessages.required") : 
                    errors?.type === 'maxLength' ? t("validationErrorMessages.long")+13 :
                    errors?.type === 'minLength' ? t("validationErrorMessages.short")+12 : ''
                }
                value={value}
                />
        </div>
    )
}

export default BoldInputPhone