import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";

const PlansView = () => {
    const endpoint = "plans";
    const [t,i18n] = useTranslation("global");
    // const title = t('locationComponent.location');
    const api = useFetch();
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const [title,setTitle] = useState("");
    const {id} = useParams();

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.subscription_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])
    
    const back = ()=>{
        nav(-1);
    }
    
    return(
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row ">
                    <div className="col-md-10 np-row">
                        <div className="row nm-row ">
                            <div className="col-md-6 mb-2">
                                <dt>{t('PlansComponent.plansName')}</dt>
                                <dd>{data.subscription_name}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('PlansComponent.plansAmount')}</dt>
                                <dd>{handlerApp.formatCurrency(data.subscription_price)}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('PlansComponent.type')}</dt>
                                <dd>{data.subscription_type}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('PlansComponent.plansDescription')}</dt>
                                <dd>{data.subscription_description}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('PlansComponent.plansLife')}</dt>
                                <dd>{data.subscription_life}</dd>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.subscription_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.subscription_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.subscription_modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.subscription_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default PlansView;

