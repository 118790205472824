import { useTranslation } from "react-i18next";

const SelectLocation = ({back})=>{
    const [t] = useTranslation("global");
    const profile  = localStorage.getItem("profile");

    return(
        profile < 3 ?
            <div className="app container">
                <div className="d-block text-center mb-4">
                    <h3 className="select-location-text">{t('companyAndLocationSelect')}</h3>
                </div>
                <div className="container bold-container-content">
                    <span className="d-block text-center text-gray light">{t('locationAndCompanySelectExplain')}.</span>
                </div>
            </div>
        :
            <div className="app container">
                <div className="d-block text-center mb-4">
                    <h3 className="select-location-text">{t('locationSelect')}</h3>
                </div>
                <div className="container bold-container-content">
                    <span className="d-block text-center text-gray light">{t('locationSelectExplain')}.</span>
                </div>
            </div>
    )
}

export default SelectLocation;