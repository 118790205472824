import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandlerApp from "../../../utils/handlerApp";
import Button from '@mui/material/Button';
import ConfirmationModal from "../Transactions/ConfirmationModal";

const TransactionsCustomerView = () => {
    const endpoint = 'transactions-customer';
    const endpointRefund = 'refund-transaction';
    const { id } = useParams();
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [card, setCard] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [actionType, setActionType] = useState('');

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let record = response.data[0];
                    setData(record);
                    setCard(JSON.parse(record.tx_card));
                    setTitle('Transaction: ' + record.tx_code);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            });
    }, [id]);

    const back = () => {
        nav(-1);
    };

    const handleSubmit = (id) => {
        setSelectedOrderId(id);
        setActionType('REFUND');
        setIsModalOpen(true);
    };

    const handleConfirmModal = () => {
        handlerApp.setLoader(true);
        let body = {
            selectedOrderId
        };
        api.post(endpointRefund, body)
            .then((response) => {
                if (response.success) {
                    setIsModalOpen(false);
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav(-1);
                } else {
                    setIsModalOpen(false);
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch((error) => {
                setIsModalOpen(false);
                handlerApp.showError(error);
                handlerApp.setLoader(false);
            });
    };

    const handleCloseModal = () => {
        setActionType('');
        setSelectedOrderId('');
        setIsModalOpen(false);
    };

    const styleCanceled = {
        background: '#d32f2f',
        color: 'white',
        height: '20px',
        width: '40%',
        borderRadius: '12px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px'
    };

    const styleSuccess = {
        background: '#56AC40',
        color: 'white',
        height: '20px',
        width: '40%',
        borderRadius: '12px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 10px'
    };

    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{ marginLeft: '10px' }}>{t('transactionsCustomer.invoice')}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.transactionId')}:</dt>
                        <dd>{data.tx_transaction_id}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.customer_email')}:</dt>
                        <dd>{data.customer_email}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.customer_name')}:</dt>
                        <dd>{data.customer_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.customer_phone')}:</dt>
                        <dd>{data.customer_phone}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.invoice_date')}:</dt>
                        <dd>{data.invoice_date}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.invoice_expired_date')}:</dt>
                        <dd>{data.invoice_expired_date}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('transactionsCustomer.transactionType')}:</dt>
                        <dd>
                            {data.tx_description === 'Charge' && (
                                <div style={styleSuccess}>{t('transactionsCustomer.charge')}</div>
                            )}
                            {data.tx_description === 'Refund' && (
                                <div style={styleCanceled}>{t('transactionsCustomer.refund')}</div>
                            )}
                        </dd>
                    </div>
                    <div className="col-md-12 mb-2">
                        <h3>{t('transactionsCustomer.invoice_details')}</h3>
                        <div className="table-responsive">
                            <table className="table">
                                <thead className="table-head">
                                    <tr>
                                        <th>{t('transactionsCustomer.invoicedetail_product')}</th>
                                        <th>{t('transactionsCustomer.invoicedetail_value')}</th>
                                        <th>{t('transactionsCustomer.invoicedetail_quantity')}</th>
                                        <th>{t('transactionsCustomer.invoicedetail_discount')}</th>
                                        <th>{t('transactionsCustomer.invoicedetail_taxes')}</th>
                                        <th>{t('transactionsCustomer.invoicedetail_subtotal')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.invoice_details && JSON.parse(data.invoice_details).map((detail, index) => (
                                        <tr key={index}>
                                            <td>{detail.invoicedetail_product}</td>
                                            <td>{handlerApp.formatCurrency(detail.invoicedetail_value)}</td>
                                            <td>{detail.invoicedetail_quantity}</td>
                                            <td>{detail.invoicedetail_discount}%</td>
                                            <td>{handlerApp.formatCurrency(detail.invoicedetail_taxes_value)}</td>
                                            <td>{handlerApp.formatCurrency(detail.invoicedetail_subtotal)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <dd>
                                {data.invoice_details && (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{t('transactionsCustomer.invoicedetail_taxes')}</th>
                                                <th>{t('transactionsCustomer.invoicedetail_total')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {JSON.parse(data.invoice_details)[0].invoicedetail_taxes &&
                                                Object.entries(JSON.parse(data.invoice_details)[0].invoicedetail_taxes).map(([taxName, taxValue], index) => (
                                                    <tr key={index}>
                                                        <td>{taxName}</td>
                                                        <td>{handlerApp.formatPercent(taxValue)}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                )}
                            </dd>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <dd>
                                {data.invoice_details && (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{t('transactionsCustomer.invoice_total')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{handlerApp.formatCurrency(data.invoice_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </dd>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {data.tx_description !== 'Refund' && (
                        <div className="col-md-2">
                            <Button variant="outlined" color="error" onClick={() => handleSubmit(data.tx_transaction_id)}>
                                {t('transactionsCustomer.refund')}
                            </Button>
                        </div>
                    )}
                </div>

                <ConfirmationModal
                    isOpen={isModalOpen}
                    actionType={actionType}
                    handleConfirm={handleConfirmModal}
                    handleClose={handleCloseModal}
                />
            </div>
        </div>
    );
};

export default TransactionsCustomerView;
