import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { LocationContext } from "../../../context/LocationContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm, Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

const AlarmsConfigurationForm = () => {
    
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()
    
    const endpoint = "orders/alarmsConfiguration";
    const [t] = useTranslation("global");
    
    const api = useFetch();
    const [data, setData] = useState([]);
    const [title, setTitle] = useState('');
    const nav = useNavigate();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        location_id: "",
        alarmconfiguration_pickup: "",
        alarmconfiguration_time_limit_normal: "",
        alarmconfiguration_time_limit_near_delay: "",
        alarmconfiguration_time_delay: "",
    });

    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const { app } = useContext(AppContext);

    const [alarmPickUp, setAlarmPickUp] = useState(moment.parseZone('00:00', "mm:ss"));
    const [normalLimit, setNormalLimit] = useState(moment.parseZone('00:00', "mm:ss"));
    const [nearDelayLimit, setNearDelayLimit] = useState(moment.parseZone('00:00', "mm:ss"));
    const [timeDelay, setTimeDelay] = useState(moment.parseZone('00:00', "mm:ss"));

    useEffect(() => {
        if (alarmPickUp?.hasOwnProperty('_d')) {
            setFormData({ ...formData, alarmconfiguration_pickup: hourToMin(alarmPickUp?.format('mm:ss')) });
        }
    }, [alarmPickUp]);
    useEffect(() => {
        if (normalLimit?.hasOwnProperty('_d')) {
            setFormData({ ...formData, alarmconfiguration_time_limit_normal: hourToMin(normalLimit?.format('mm:ss')) });
        }
    }, [normalLimit]);
    useEffect(() => {
        if (nearDelayLimit?.hasOwnProperty('_d')) {
            setFormData({ ...formData, alarmconfiguration_time_limit_near_delay: hourToMin(nearDelayLimit?.format('mm:ss')) });
        }
    }, [nearDelayLimit]);
    useEffect(() => {
        if (timeDelay?.hasOwnProperty('_d')) {
            setFormData({ ...formData, alarmconfiguration_time_delay: hourToMin(timeDelay?.format('mm:ss')) });
        }
    }, [timeDelay]);

    useEffect(() => {
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    useEffect(() => {
        setTitle(t("alarmsConfigurationComponent.createAlarmsConfiguration"));
        if (id !== undefined) {
            setTitle(t('alarmsConfigurationComponent.editAlarmsConfiguration'));
        }
    }, [t])

    // location
    const { location } = useContext(LocationContext);
    const validateLocation = true;
    useEffect(() => {
        if (location === "") {
            if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                handlerApp.showAlertLocation();
                setData([]);
            }
        } else {
            handlerApp.setLoader(true);
            if (id !== undefined) {

                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        }
        setFormData({
            ...formData,
            location_id: location,
        });
    }, [location])

    const minToHour =(totalSeconds)=>{
        const hours = Math.floor(totalSeconds / 60);
        const minutes = totalSeconds % 60;

        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        return formattedTime
    }

    function hourToMin (tiempo) {
        const [horas, minutos] = tiempo.split(":").map(Number);
        const totalMinutos = horas * 60 + minutos;
        
        return totalMinutos;
    }
    

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.alarmconfiguration_pickup != null) {
            setAlarmPickUp(moment.parseZone(minToHour(d.alarmconfiguration_pickup), "mm:ss"));
        }
        if (d.alarmconfiguration_time_limit_normal != null) {
            setNormalLimit(moment.parseZone(minToHour(d.alarmconfiguration_time_limit_normal), "mm:ss"));
        }
        if (d.alarmconfiguration_time_limit_near_delay != null) {
            setNearDelayLimit(moment.parseZone(minToHour(d.alarmconfiguration_time_limit_near_delay), "mm:ss"));
        }
        if (d.alarmconfiguration_time_delay != null) {
            setTimeDelay(moment.parseZone(minToHour(d.alarmconfiguration_time_delay), "mm:ss"));
        }
        setFormData(d);
    };

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];

            setValue(key, data[key]);
        }
        return finalData;
    };

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    setDisableButton(false)
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    // const handleForm = (e, name = null, dependency = null) => {
    //     let key = name !== null ? name : e.target.name;
    //     setFormData({
    //         ...formData,
    //         [key]: e.target.value,
    //     });

    //     dependency(e.target.value);
    //     if (dependency !== null) {
    //         dependency(e.target.value);
    //     }
    // };

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitLocal = (data) => {

        handlerApp.setLoader(true);
        api
            .post(endpoint, formData)
            .then((response) => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch((error) => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            });
    };    

    const handleUpdate = (data) => {

        handlerApp.setLoader(true);
        api
            .put(endpoint + "/" + id, formData)
            .then((response) => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch((error) => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            });
    };

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    return (
        <div className="app container">
            <div className="title-section-forms">{title}</div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-4 mb-2">
                            <TimePicker
                                ampm={false}
                                openTo="minutes"
                                views={[ 'minutes', 'seconds']}
                                format="mm:ss"
                                label={t('alarmsConfigurationComponent.alarmConfigurationPickup')+" (hh:mm)"}
                                value={alarmPickUp}
                                onChange={(val) => setAlarmPickUp(val)}
                                
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <TimePicker
                                ampm={false}
                                openTo="minutes"
                                views={[ 'minutes', 'seconds']}
                                format="mm:ss"
                                label={t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNormal')+" (hh:mm)"}
                                value={normalLimit}
                                onChange={(val) => setNormalLimit(val)}
                                
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <TimePicker
                                ampm={false}
                                openTo="minutes"
                                views={[ 'minutes', 'seconds']}
                                format="mm:ss"
                                label={t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNearDelay')+" (hh:mm)"}
                                value={nearDelayLimit}
                                onChange={(val) => setNearDelayLimit(val)}
                                
                            />
                        </div>

                        <div className="col-md-4 mb-2">
                            <TimePicker
                                ampm={false}
                                openTo="minutes"
                                views={[ 'minutes', 'seconds']}
                                format="mm:ss"
                                label={t('alarmsConfigurationComponent.alarmConfigurationTimeDelay')+" (hh:mm)"}
                                value={timeDelay}
                                onChange={(val) => setTimeDelay(val)}
                                
                            />
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button
                                    className="bold-btn bold-btn-light"
                                    onClick={handleCancel}
                                >
                                    {t("cancel")}
                                </button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AlarmsConfigurationForm;